import { useState } from "react";
import UtilityModal from "../../hooks/useModal"
import {
    CardText,
} from "reactstrap";
import axios from "axios";
import { CircularProgress, Box } from "@mui/material";
import { toast } from "react-toastify";
import BuilderToast from "../../lib/BuilderToast";

const validateText = (value) => {
    if (typeof value !== "string") {
        return { success: false, message: `"${value}" not a string.` }
    }
    return { success: true }
}

const validateNumber = (value) => {
    if (typeof value !== "number") {
        return { success: false, message: `"${value}" not a number.` }
    }
    return { success: true }
}

const validateOptions = (field, value) => {
    const options = field.options.map(option => option.value);
    if (!options.includes(value)) {
        return { success: false, message: `"${value}" not in options` }
    }
    return { success: true }
}

const validateObject = (field, value) => {
    for (const key in field.objectFields) {
        if (field.objectFields.hasOwnProperty(key)) {
            const fieldSchema = field.objectFields[key];
            if (!Object.keys(value).includes(key))
                return { success: false, message: `"${key}" key doesn't exist.` }
            const val = value[key];
            const validation = validateData(fieldSchema, val)
            if (!validation.success) {
                return validation;
            }
        }
    }
    return { success: true }
}

const validateArray = (field, value) => {
    if (!Array.isArray(value)) {
        return { success: false, message: `It is not an array.` }
    }
    for (const val of value) {
        const validation = validateData({ type: "object", objectFields: field.arrayFields }, val)
        if (!validation.success) {
            return validation;
        }
    }
    return { success: true }
}

const validateData = (field, value) => {

    if (field.type === "text" || field.type === "textarea") {
        return validateText(value);
    } else if (field.type === "radio" || field.type === "select") {
        return validateOptions(field, value);
    } else if (field.type === "number") {
        return validateNumber(value);
    } else if (field.type === "object") {
        return validateObject(field, value);
    } else if (field.type === "array") {
        return validateArray(field, value);
    }
    return { success: true }
}

const FetchFieldData = ({ field, setFetchFieldShow, Component, value, onChange, label }) => {
    const [currentData, setCurrentData] = useState(value)
    const [isFetching, setIsFetching] = useState(false);
    const [url, setUrl] = useState("")
    const [error, setError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = validateData(field, currentData);
        if (validation.success) {
            onChange(currentData);
            setFetchFieldShow(false)
            toast.success(<BuilderToast category="fieldChanged" />)
        } else {
            setError(validation.message);
            setTimeout(() => { setError(null); }, 2000);
        }
    };

    const fetchData = async (e) => {
        e.preventDefault();
        setIsFetching(true)
        try {
            const response = await axios.get(url);
            setCurrentData(response.data);
        } catch (e) {
            setError("Error while fetching.");
            setTimeout(() => { setError(null); }, 2000);
        }
        setIsFetching(false)
    }

    return (
        <UtilityModal showModal={true} setShowModal={setFetchFieldShow}>
            <form onSubmit={handleSubmit} className="tw-max-w-sm tw-m-5">

                <div className="tw-mb-5">
                    <div className="tw-grid tw-grid-cols-8 tw-gap-2 tw-w-full tw-max-w-md tw-mb-5">
                        <input
                            type="text"
                            onChange={(e) => setUrl(e.currentTarget.value)}
                            className="tw-col-span-6 tw-bg-background tw-border tw-border-outline tw-text-onBackground tw-text-base tw-rounded-lg focus:tw-ring-primary focus:tw-border-primary tw-block tw-w-full tw-p-2.5 dark:tw-bg-backgroundDark dark:tw-border-outlineDark dark:tw-placeholder-onBackgroundDark dark:tw-text-onBackgroundDark dark:focus:tw-ring-primaryDark dark:focus:tw-border-primaryDark"
                            value={url}
                        />
                        <button
                            className="tw-col-span-2 tw-text-onPrimary tw-bg-primary hover:tw-bg-primary focus:tw-outline-none tw-font-medium tw-rounded-lg tw-text-base tw-w-full sm:tw-w-auto tw-py-2.5 tw-text-center dark:tw-bg-primaryDark dark:hover:tw-bg-primaryDark disabled:tw-opacity-80 tw-items-center tw-inline-flex tw-justify-center"
                            onClick={fetchData}
                            disabled={!url}
                        >
                            {isFetching ?
                                <CircularProgress
                                    size={22}
                                    sx={{
                                        color: "white",
                                    }}
                                />
                                :
                                "Fetch"
                            }
                        </button>
                    </div>
                    <Component value={value} field={field} onChange={onChange} label={label} currentData={currentData} setCurrentData={setCurrentData} />
                </div>

                {error && (
                    <CardText className="mb-2" style={{ color: "red" }}>
                        {error}
                    </CardText>
                )}
                <button
                    type="submit"
                    className="tw-text-onPrimary tw-bg-primary hover:tw-bg-primaryContainer hover:tw-text-black focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-primaryContainer tw-font-medium tw-rounded-lg tw-text-sm tw-w-full sm:tw-w-auto tw-px-5 tw-py-2.5 tw-text-center dark:tw-text-onPrimaryDark dark:tw-bg-primaryDark dark:hover:tw-bg-primaryContainerDark dark:focus:tw-ring-primaryContainerDark"
                >
                    Apply
                </button>
            </form>
        </UtilityModal>
    )
}

export default FetchFieldData