import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ImageField } from "../../../puck_fields";
import { useWebsiteContext } from "../../../../context/website";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type GalleryProps = globalCommonFieldsType & {
    images: {
        src: string;
        label: string;
    }[];
};

export const Gallery: ComponentConfig<GalleryProps> = {
    fields: {
        images: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Image ${i + 1}`,
            arrayFields: {
                src: { ...ImageField, label: "Url" },
                label: { type: "text", label: "Label" },
            },
            defaultItemProps: {
                src: "",
                label: "#",
            },
            label: "Images"
        },
        ...globalCommonFields
    },
    defaultProps: {
        images: [
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg', label: 'Image 0' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg', label: 'Image 1' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-2.jpg', label: 'Image 2' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg', label: 'Image 3' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg', label: 'Image 4' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg', label: 'Image 5' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg', label: 'Image 6' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg', label: 'Image 7' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg', label: 'Image 8' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg', label: 'Image 9' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg', label: 'Image 10' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg', label: 'Image 11' }
        ],
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 25, right: 0, bottom: 25, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext()

        const responsive = {
            breakpoint3: {
                breakpoint: { max: 3000, min: 1490 },
                items: 5,
                slidesToSlide: 5
            },
            breakpoint2: {
                breakpoint: { max: 1490, min: 980 },
                items: 3,
                slidesToSlide: 3
            },
            breakpoint1: {
                breakpoint: { max: 980, min: 620 },
                items: 2,
                slidesToSlide: 2
            },
            breakpoint0: {
                breakpoint: { max: 620, min: 0 },
                items: 1,
                slidesToSlide: 1
            }
        };

        const theme = websiteTheme.name === "theme_2" ? websiteTheme.content : themes.theme_2
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper isCenteredLayout={false} className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin), overflow: "hidden" }} className={`tw-px-4 lg:tw-px-6 tw-py-5`}>
                    <Carousel
                        responsive={responsive}
                        autoPlay={false}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        infinite={true}
                        partialVisible={false}
                        dotListClass="custom-dot-list-style"
                        arrows={false}
                        className=""
                    >
                        {data.images.map((image, i) =>
                            <div key={i} className="tw-shadow tw-rounded-lg tw-overflow-hidden tw-h-[270px] tw-w-[270px] md:tw-h-[310px] md:tw-w-[310px] tw-relative tw-pointer-events-none tw-mx-auto">
                                <div className="tw-h-full tw-w-full tw-p-6 tw-absolute">
                                    <div style={{ outline: "0.5px solid white" }} className="tw-h-full tw-w-full" />
                                </div>
                                <img alt="" src={image.src} className="tw-object-cover tw-w-full tw-h-full"></img>
                            </div>
                        )}
                    </Carousel>
                </div>
            </Wrapper >

        );
    },
};