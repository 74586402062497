import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableText } from "../../../utils/components";
import styled from 'styled-components';
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

// for dynamic grid styling
const GridContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  margin: auto;
  max-width: 1280px;
  grid-template-columns: ${props => `repeat(${props.xs.toString()}, minmax(0, 1fr))`};

  @media (min-width: 640px) {
    grid-template-columns: ${props => `repeat(${props.sm.toString()}, minmax(0, 1fr))`};
  }

  @media (min-width: 768px) {
    grid-template-columns: ${props => `repeat(${props.md.toString()}, minmax(0, 1fr))`};
  }
`;

export type GalleryProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    }; columns: number;
    images: {
        src: string;
        label: string;
    }[];
};

export const Gallery: ComponentConfig<GalleryProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        columns: { type: "number", label: "Columns" },
        images: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Image ${i + 1}`,
            arrayFields: {
                src: { ...ImageField, label: "Url" },
                label: { type: "text", label: "Label" },
            },
            defaultItemProps: {
                src: "",
                label: "#",
            },
            label: "Images"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Gallery",
            typography: createDefaultProps("text", {
                fontSize: 36,
                fontWeight: 800,
                lineHeight: 1.3,
            }),
        },
        columns: 4,
        images: [
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg', label: 'Image 0' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg', label: 'Image 1' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-2.jpg', label: 'Image 2' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg', label: 'Image 3' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg', label: 'Image 4' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg', label: 'Image 5' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg', label: 'Image 6' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg', label: 'Image 7' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg', label: 'Image 8' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg', label: 'Image 9' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg', label: 'Image 10' },
            { src: 'https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg', label: 'Image 11' }
        ],
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 25, right: 0, bottom: 25, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext()

        let rows
        if (data.images.length % data.columns == 0)
            rows = Math.floor(data.images.length / data.columns);
        else
            rows = Math.floor(data.images.length / data.columns) + 1;

        const groupedImages = [];
        for (let i = 0; i < data.images.length; i += rows) {
            groupedImages.push(data.images.slice(i, i + rows));
        }

        let md = data.columns;
        let sm = 0, xs = 0;

        if (md == 4 || md == 3 || md == 2) {
            sm = 2;
            xs = 1;
        } else if (md == 1) {
            sm = 1;
            xs = 1;
        } else if (md > 4) {
            sm = 2;
            xs = 1;
        }

        const handleChange = (e, type) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Gallery_theme_6_variant_1" }
        };

        const theme = websiteTheme.name === "theme_6" ? websiteTheme.content : themes.theme_6
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className={`tw-px-4 lg:tw-px-6 tw-py-5 tw-text-center`}>
                    <EditableText classes="tw-mb-6" handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                        {data.title.text}
                    </EditableText>
                    <GridContainer xs={xs} sm={sm} md={md}>
                        {groupedImages.map((imageSet, setIndex) => (
                            <div key={setIndex} className="tw-grid tw-gap-4">
                                {imageSet.map((image, index) => (
                                    <div key={index} className="tw-max-w-full tw-rounded-lg">
                                        <img className="tw-h-auto tw-w-full" src={image.src} alt={image.label} />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </GridContainer>
                </div>
            </Wrapper >

        );
    },
};