import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { IoMdClose } from "react-icons/io";

const UtilityModal = ({ children, showModal, setShowModal, fullBox = false }) => {
    return (
        <Modal scrollable isOpen={showModal} toggle={() => setShowModal(!showModal)} contentClassName={`${fullBox ? "tw-w-[90vw] tw-h-[85vh]" : "tw-flex tw-flex-col tw-items-center tw-max-w-fit tw-max-h-fit"} `} centered>
            <ModalHeader className="tw-w-full tw-flex tw-justify-end tw-py-2">
                <span onClick={() => setShowModal(false)} className={`tw-cursor-pointer tw-p-1 tw-rounded hover:tw-shadow-md`}>
                    <IoMdClose size={20} />
                </span>
            </ModalHeader>
            <ModalBody >
                {children}
            </ModalBody>
        </Modal>
    );
};

export default UtilityModal;