import { useState } from "react";
import { CustomField } from "@measured/puck";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useWebsiteContext } from "../../context/website";

export const Uploader = ({ onChange, value }) => {
    const { setUploadImageModal } = useWebsiteContext();

    return (
        <span className="tw-cursor-pointer hover:tw-text-primary">
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    setUploadImageModal({
                        show: true,
                        url: value,
                        setUrl: onChange
                    });
                }} className="tw-cursor-pointer hover:tw-text-primary dark:tw-text-white">
                <FaCloudUploadAlt size={18} />
            </span>
        </span>
    )
}

export const ImageField: CustomField<string> = {
    type: "custom",
    render: ({ onChange, value, field }: {
        onChange: (value: any) => void;
        value: string;
        field: any;
    }) => {

        return (
            <div className="tw-relative tw-py-2">
                <label className="tw-block">
                    <div className="tw-flex tw-gap-3">
                        <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{field.label}</div>
                        <Uploader value={value} onChange={onChange} />
                    </div>
                    <input
                        className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500 tw-w-full"
                        defaultValue={value}
                        onChange={(e) => onChange(e.currentTarget.value)}
                        type="text"
                    />
                </label>
            </div>
        )
    },
}