import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { FaPlus, FaMinus } from "react-icons/fa";
import { EditableText } from "../../../utils/components";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";
import { useWebsiteContext } from "../../../../context/website";

export type FaqProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    items: {
        label: string,
        description: string,
    }[];
    backgroundImage: string;
};

export const Faq: ComponentConfig<FaqProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        items: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Faq ${i + 1}`,
            arrayFields: {
                label: { type: "textarea" },
                description: { type: "textarea" },
            },
            defaultItemProps: {
                label: "",
                description: "",
            },
            label: "Questions"
        },
        backgroundImage: { ...ImageField, label: "Background Image" },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Hair can be red or brown,come on in and we’ll change your crown.",
            typography: createDefaultProps("text", {
                fontSize: 36,
                fontWeight: 800,
                lineHeight: 1.05,
            }),
        },
        items: [
            {
                "label": "How can I book an appointment?",
                "description": "To book an appointment, you can either call our salon or spa directly, visit our website to book online."
            },
            {
                "label": "What services do you offer?",
                "description": "We offer a wide range of services including haircuts, hair coloring, manicures, pedicures, massages, facials, waxing, and more. Feel free to explore our website for a detailed list of services."
            },
            {
                "label": "Do you offer any memberships or packages?",
                "description": "Yes, we offer various memberships and packages that provide discounts on our services. You can inquire at our reception desk or check our website for more information."
            },
            {
                "label": "Do I need to bring anything for my appointment?",
                "description": "For most appointments, you don't need to bring anything special. However, if you have any specific requirements or if you're coming in for a specific service, our staff will inform you if you need to bring anything."
            },
            {
                "label": "What are your operating hours?",
                "description": "Our operating hours vary depending on the day of the week. You can find our current opening and closing hours on our website or by contacting us directly."
            },
            {
                "label": "What can I expect during my visit?",
                "description": "During your visit, you can expect a relaxing and rejuvenating experience. Our friendly staff will greet you upon arrival, discuss your needs, and guide you through your chosen services. If it's your first visit, we'll provide you with a brief orientation to ensure you're comfortable."
            }
        ],
        backgroundImage: "https://images.fresha.com/locations/location-profile-images/946641/1208694/15afd873-4648-4026-80cb-8aaf7d3e5d96.jpg?class=venue-gallery-large",
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 64, right: 0, bottom: 64, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const [activeIndex, setActiveIndex] = useState(null);

        const toggleAnswer = (index) => {
            setActiveIndex(activeIndex === index ? null : index);
        };

        const handleChange = (e, type) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Faq_theme_2_variant_1" }
        };

        const theme = websiteTheme.name === "theme_2" ? websiteTheme.content : themes.theme_2
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper isCenteredLayout={false} className="" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className="lg:tw-grid-cols-2 tw-grid tw-justify-center tw-items-center">
                    <div className="tw-w-full tw-h-full tw-hidden lg:tw-block tw-relative">
                        <img src={data.backgroundImage} className="tw-object-cover tw-w-full tw-h-full tw-opacity-[0.15]"></img>
                        <div style={{ backgroundImage: theme.colors.primary }} className="tw-h-full tw-w-fuzll tw-p-6" />
                    </div>

                    <div style={{ backgroundColor: theme.colors.primary }} className={`tw-flex tw-flex-col tw-z-10 tw-gap-5 tw-p-10 lg:tw-p-16`}>
                        <EditableText classes="tw-mb-4" handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>

                        <div style={{ backgroundColor: theme.colors.secondary }} className="tw-h-[4px] tw-w-[60px] tw-rounded tw-mb-4 tw-mx-auto md:tw-mx-0"></div>


                        <DropZone zone="faq_theme_2_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />


                        <div className="tw-space-y-6">
                            {data.items.map((faq, i) => (
                                <div
                                    key={i}
                                    className="tw-border-[1px] tw-border-gray-200 tw-rounded-lg"
                                >
                                    <button
                                        style={{ color: theme.colors.text }}
                                        className="tw-flex tw-items-center tw-justify-between tw-w-full tw-p-6 focus:tw-outline-none tw-bg-transparent"
                                        onClick={() => toggleAnswer(i)}
                                    >
                                        <h1 className="tw-font-semibold tw-text-lg tw-text-inherit">
                                            {faq.label}
                                        </h1>

                                        <span className="tw-border-[1px] tw-border-gray-200 tw-rounded-full tw-py-[3px] tw-px-[6px]">
                                            {activeIndex === i ? (
                                                <FaMinus size={14} />
                                            ) : (
                                                <FaPlus size={14} />
                                            )}
                                        </span>
                                    </button>

                                    {activeIndex === i && faq.description && (
                                        <>
                                            <hr className="tw-border-gray-200" />
                                            <p style={{ fontFamily: "Montserrat" }} className="tw-px-6 tw-py-4 tw-text-md">
                                                {faq.description}
                                            </p>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </Wrapper>

        );
    },
};