import { ColorField as CustomColorField, FontField as CustomFontField, ImageField as CustomImageField } from "../puck_fields";
import { ObjectField, CustomField, SelectField, NumberField } from "@measured/puck";

type basicFieldSet = "size" | "spacing" | "border" | "text"
type generalFieldSet = "globalCommon" | "textCommon"

type CommonFieldSet = basicFieldSet | generalFieldSet;

// Very Basic Field Sets

export type SizeFieldsType = {
    width: number;
    height: number;
};

export const sizeFields = {
    width: { type: "number", label: "Width" } as NumberField,
    height: { type: "number", label: "Height" } as NumberField,
};

export type SpacingFieldsType = {
    margin: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
    padding: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
};

export const spacingFields = {
    margin: {
        type: "object",
        objectFields: {
            top: { type: "number", label: "Top" },
            right: { type: "number", label: "Right" },
            bottom: { type: "number", label: "Bottom" },
            left: { type: "number", label: "Left" },
        },
        label: "Margin"
    },
    padding: {
        type: "object",
        objectFields: {
            top: { type: "number", label: "Top" },
            right: { type: "number", label: "Right" },
            bottom: { type: "number", label: "Bottom" },
            left: { type: "number", label: "Left" },
        },
        label: "Padding"
    }
};

type BorderStyles = "none" | "hidden" | "dotted" | "dashed" | "solid" | "double" | "groove" | "ridge" | "inset" | "outset" | "initial" | "inherit";

const borderStyleOptions = [
    { label: "None", value: "none" },
    { label: "Hidden", value: "hidden" },
    { label: "Dotted", value: "dotted" },
    { label: "Dashed", value: "dashed" },
    { label: "Solid", value: "solid" },
    { label: "Double", value: "double" },
    { label: "Groove", value: "groove" },
    { label: "Ridge", value: "ridge" },
    { label: "Inset", value: "inset" },
    { label: "Outset", value: "outset" },
    { label: "Initial", value: "initial" },
    { label: "Inherit", value: "inherit" }
];

export type BorderFieldsType = {
    top: {
        style: BorderStyles;
        width: number;
        radius: {
            left: number;
            right: number;
        };
        color: object;
    },
    right: {
        style: BorderStyles;
        width: number;
        color: object;
    },
    bottom: {
        style: BorderStyles;
        width: number;
        radius: {
            left: number;
            right: number;
        };
        color: object;
    },
    left: {
        style: BorderStyles;
        width: number;
        color: object;
    }
};

export type ColorFieldType = {
    hex: string;
};

export const ColorField = {
    hex: { ...CustomColorField, label: "Hex" } as CustomField<string>
};

export type FontFieldType = {
    name: string;
};

export const FontField = {
    name: { ...CustomFontField, label: "Name" } as CustomField<string>
};

export type ImageFieldType = {
    url: string;
};

export const ImageField = {
    url: { ...CustomImageField, label: "Url" } as CustomField<string>
};

export const borderFields = {
    top: {
        type: "object",
        objectFields: {
            style: {
                type: "select",
                options: borderStyleOptions,
                label: "Style"
            },
            width: { type: "number", label: "Width" },
            radius: {
                type: "object",
                objectFields: {
                    left: { type: "number", label: "Left" },
                    right: { type: "number", label: "Right" }
                },
                label: "Radius"
            },
            color: { type: "object", objectFields: ColorField, label: "Color" } as ObjectField<ColorFieldType>,
        },
        label: "Top"
    },
    right: {
        type: "object",
        objectFields: {
            style: {
                type: "select",
                options: borderStyleOptions,
                label: "Style"
            },
            width: { type: "number", label: "Width" },
            color: { type: "object", objectFields: ColorField, label: "Color" } as ObjectField<ColorFieldType>,
        },
        label: "Right"
    },
    bottom: {
        type: "object",
        objectFields: {
            style: {
                type: "select",
                options: borderStyleOptions,
                label: "Style"
            },
            width: { type: "number", label: "Width" },
            radius: {
                type: "object",
                objectFields: {
                    left: { type: "number", label: "Left" },
                    right: { type: "number", label: "Right" }
                },
                label: "Radius"
            },
            color: { type: "object", objectFields: ColorField, label: "Color" } as ObjectField<ColorFieldType>,
        },
        label: "Bottom"
    },
    left: {
        type: "object",
        objectFields: {
            style: {
                type: "select",
                options: borderStyleOptions,
                label: "Style"
            },
            width: { type: "number", label: "Width" },
            color: { type: "object", objectFields: ColorField, label: "Color" } as ObjectField<ColorFieldType>,
        },
        label: "Left"
    },
}

export type textFieldsType = {
    font: FontFieldType;
    fontSize?: number;
    fontWeight?: number;
    style?: "normal" | "italic" | "oblique";
    lineHeight?: number;
    letterSpacing?: number;
    transform?: "none" | "uppercase" | "lowercase" | "capitalize";
    decoration?: "none" | "underline" | "overline" | "line-through";
    align?: "left" | "right" | "center" | "justify" | "inherit";
    color: ColorFieldType;
};

export const textFields = {
    font: { type: "object", objectFields: FontField, label: "Font" } as ObjectField<FontFieldType>,
    fontSize: { type: "number", label: "Font Size" } as NumberField,
    fontWeight: { type: "number", label: "Font Weight" } as NumberField,
    lineHeight: { type: "number", label: "Line Height" } as NumberField,
    letterSpacing: { type: "number", label: "Letter Spacing" } as NumberField,
    align: {
        type: "select",
        options: [
            { label: "Left", value: "left" },
            { label: "Right", value: "right" },
            { label: "Center", value: "center" },
            { label: "Justify", value: "justify" },
            { label: "Inherit", value: "inherit" },
        ],
        label: "Align"
    } as SelectField,
    style: {
        type: "select",
        options: [
            { label: "Normal", value: "normal" },
            { label: "Italic", value: "italic" },
            { label: "Oblique", value: "oblique" },
        ],
        label: "Style"
    } as SelectField,
    decoration: {
        type: "select",
        options: [
            { label: "None", value: "none" },
            { label: "Underline", value: "underline" },
            { label: "Overline", value: "overline" },
            { label: "Line Through", value: "line-through" },
        ],
        label: "Decoration"
    } as SelectField,
    transform: {
        type: "select",
        options: [
            { label: "None", value: "none" },
            { label: "Uppercase", value: "uppercase" },
            { label: "Lowercase", value: "lowercase" },
            { label: "Capitalize", value: "capitalize" },
        ],
        label: "Transform"
    } as SelectField,
    color: { type: "object", objectFields: ColorField, label: "Color" } as ObjectField<ColorFieldType>
}

// General Field Sets
export type globalCommonFieldsType = {
    spacing: SpacingFieldsType;
    border: BorderFieldsType;
    backgroundColor: ColorFieldType;
};

export const globalCommonFields = {
    spacing: {
        type: "object",
        objectFields: spacingFields,
        label: "Spacing"
    } as ObjectField<SpacingFieldsType>,
    border: {
        type: "object",
        objectFields: borderFields,
        label: "Border"
    } as ObjectField<BorderFieldsType>,
    backgroundColor: { type: "object", objectFields: ColorField, label: "Background" } as ObjectField<ColorFieldType>
}


// Default props for each field set
const basicDefaultProps = {
    size: {
        width: 100,
        height: 100,
    },
    spacing: {
        margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
    border: {
        top: {
            style: "none",
            width: 0,
            radius: {
                left: 0,
                right: 0
            },
            color: { hex: "#000000" }
        },
        right: {
            style: "none",
            width: 0,
            color: { hex: "#000000" }
        },
        bottom: {
            style: "none",
            width: 0,
            radius: {
                left: 0,
                right: 0
            },
            color: { hex: "#000000" }
        },
        left: {
            style: "none",
            width: 0,
            color: { hex: "#000000" }
        },
    },
    text: {
        font: { name: "Default" },
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: 0,
        color: { hex: "Default" },
        align: "inherit",
        style: "normal",
        decoration: "none",
        transform: "none",
    }
}

// for creating default props
const deepMerge = (target, source) => {
    for (const key in source) {
        if (source[key] instanceof Object && key in target) {
            Object.assign(source[key], deepMerge(target[key], source[key]));
        }
    }
    return { ...target, ...source };
};

const generalDefaultProps = {
    globalCommon: {
        backgroundColor: { hex: "Default" },
        spacing: basicDefaultProps.spacing,
        border: basicDefaultProps.border
    },
};

export const createDefaultProps = (set: CommonFieldSet = "globalCommon", overrides: Record<string, any> = {}) => {
    const selectedDefaults = (set in basicDefaultProps) ? basicDefaultProps[set] : generalDefaultProps[set];
    return deepMerge(selectedDefaults, overrides);
};