/* eslint-disable */
import axios from "axios";
import { toast } from "react-toastify";
import BuilderToast from "../lib/BuilderToast";

const BASE_URL_GROWTHZILLA = process.env.REACT_APP_ENV === "development" ? "http://localhost:8080" : "/growthzilla/remote/public";

const callApi = async (url, method = "GET", data, headers, params) => {
    const config = {
        method,
        url: BASE_URL_GROWTHZILLA + url,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
        data,
        params,
    };
    return axios(config)
        .then((response) => {
            if (
                response.data?.status === false &&
                response.data?.message === "Please log in to continue."
            ) {
                window.postMessage({ method: "loggedOut" });
            }
            return response.data;
        })
        .catch((err) => {
            toast.error(<BuilderToast category="commonError" />, { toastId: "commonError" })
            return err.response?.data;
        });
};

export const callApiDam = callApi;