import { useWebsiteContext } from "../../context/website";
import { fonts as fontList } from "../../theme";
import { LoadFonts } from "../../lib/FontLoader";
import { CustomField } from "@measured/puck";

// Font component
export const FontField: CustomField<string> = {
    type: "custom",

    render: ({ onChange, value, field }: {
        onChange: (value: any) => void;
        value: string;
        field: any;
    }) => {
        const { websiteFonts, setWebsiteFonts } = useWebsiteContext();
        return (
            <label className="tw-py-2 tw-block">
                <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{field.label}</div>
                <select className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:tw-focus:ring-blue-500 dark:tw-focus:border-blue-500"
                    onChange={(e) => {
                        onChange(e.currentTarget.value)
                        if (e.currentTarget.value !== "Default" && !websiteFonts.includes(e.currentTarget.value)) {
                            setWebsiteFonts([...websiteFonts, e.currentTarget.value])
                        }
                    }}
                    defaultValue={value}>
                    {fontList.map((font, i) => (
                        <option key={i} value={font}>{font}</option>
                    ))}
                </select>
            </label>
        )
    }
}