import React from "react";
import { ComponentConfig, ObjectField } from "@measured/puck";
import { FaVideo } from "react-icons/fa";
import { Tooltip } from "../utils/Tooltip";
import Wrapper from "../utils/Wrapper";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../helpers'
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type SizeFieldsType, sizeFields } from "../utils/CommonFields";
import { useWebsiteContext } from "../../context/website";

export type VideoProps = globalCommonFieldsType & {
    src?: string,
    size: SizeFieldsType
};

export const Video: ComponentConfig<VideoProps> = {
    fields: {
        src: { type: "text", label: "Url" },
        size: {
            type: "object",
            objectFields: sizeFields,
            label: "Size"
        },
        ...globalCommonFields
    },
    defaultProps: {
        src: "",
        size: {
            height: 100,
            width: 200,
        },
        ...createDefaultProps("globalCommon")
    },
    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const theme = websiteTheme.content
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div
                    style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin), ...generateBorderCSS(data.border) }}>
                    <span>
                        <video height={data.size.height} width={data.size.width} controls>
                            <source src={data.src} />
                        </video>
                    </span>
                </div >
            </Wrapper>
        );
    },
};

export const VideoWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2" : "tw-px-2 tw-py-1"} tw-border-transparent tw-rounded tw-shadow-md tw-w-fit tw-flex tw-flex-col tw-items-center tw-gap-1 tw-bg-background dark:tw-bg-backgroundDark`}>
        {showIcon &&
            (
                showText ?
                    <FaVideo size={22} />
                    :
                    <Tooltip title="Video">
                        <span>
                            <FaVideo size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Video</div>
        }
    </div>;
};