import { useCallback, useState, memo } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Menu, Transition } from '@headlessui/react'
import { AiTwotoneDelete } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaCloudDownloadAlt } from "react-icons/fa";
import FetchFieldData from "../utils/FetchFieldData";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en'

// For fetching for each field
export const FetchWrapper = ({ Component, value, onChange, label, field }) => {
    const [fetchFieldShow, setFetchFieldShow] = useState(false);

    return (
        <span className="tw-cursor-pointer hover:tw-text-primary">
            <span onClick={(e) => {
                e.stopPropagation();
                setFetchFieldShow(true);
            }} className="tw-cursor-pointer hover:tw-text-primary dark:tw-text-white">
                <FaCloudDownloadAlt size={18} />
            </span>
            {fetchFieldShow &&
                <FetchFieldData field={field} setFetchFieldShow={setFetchFieldShow} Component={Component} value={value} onChange={onChange} label={label} />
            }
        </span>
    )
}

interface TextFetchComponentProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
    currentData: string;
    setCurrentData: (value: string) => void;
}

// for fetching text
export const TextFetchComponent: React.FC<TextFetchComponentProps> = memo(({ label, value, onChange, currentData, setCurrentData }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentData(e.currentTarget.value)
    }

    return (
        <>
            <label
                className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-primary dark:tw-text-primaryDark"
            >
                {label}
            </label>
            <input
                type="text"
                value={currentData}
                onChange={handleChange}
                className="tw-bg-background tw-border tw-border-outline tw-text-onBackground dark:tw-bg-backgroundDark dark:tw-border-outlineDark dark:tw-text-onBackgroundDark tw-text-sm tw-rounded-lg focus:tw-ring-primary focus:tw-border-primary dark:focus:tw-ring-primaryDark dark:focus:tw-border-primaryDark tw-block tw-w-full tw-p-2.5"
                required
            />
        </>
    )
})

interface ObjectFetchComponentProps {
    label: string;
    value: object;
    onChange: (value: object) => void;
    currentData: object;
    setCurrentData: (value: object) => void;
}

export const ComplexFetchComponent: React.FC<ObjectFetchComponentProps> = memo(({ label, value, onChange, currentData, setCurrentData }) => {
    const handleChange = (newValue: any) => {
        if (!newValue.error)
            setCurrentData(newValue.jsObject)
    };

    return (
        <>
            <label className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-primary dark:tw-text-primaryDark">{label}</label>
            <div className="tw-shadow tw-py-[10px] tw-px-[5px]">
                <JSONInput
                    locale={locale}
                    placeholder={currentData}
                    colors={{ background: "inherit" }}
                    theme="light_mitsuketa_tribute"
                    height="200px"
                    width="100%"
                    onBlur={handleChange}
                />
            </div>
        </>
    );
});


// Text component
export const TextComponent = ({ onChange, value, label }) => {
    return (
        <label className="tw-py-2 tw-block">
            <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{label}</div>
            <input
                className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500 tw-w-full"
                defaultValue={value}
                onChange={(e) => onChange(e.currentTarget.value)}
                type="text"
            />
        </label>
    )
};

// Textarea component
export const TextareaComponent = ({ onChange, value, label }) => {
    return (
        <label className="tw-py-2 tw-block">
            <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{label}</div>
            <textarea
                className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500 tw-w-full"
                defaultValue={value}
                onChange={(e) => onChange(e.currentTarget.value)}
            />
        </label>
    )
};

// Select component
export const SelectComponent = ({ field, onChange, label, value }) => {
    return (
        <label className="tw-py-2 tw-block">
            <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{label}</div>
            <select className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:tw-focus:ring-blue-500 dark:tw-focus:border-blue-500" onChange={(e) => onChange(e.currentTarget.value)} defaultValue={value}>
                {field.options?.map((option, i) => (
                    <option key={i} value={option.value}>{option.label}</option>
                ))}
            </select>
        </label>
    )
};

// Radio component
export const RadioComponent = ({ field, onChange, label, value }) => {
    return (
        <label className="tw-py-2 tw-block">
            <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{label}</div>
            <div className="tw-flex tw-items-center tw-gap-2 tw-flex-wrap">
                {field.options?.map((option, i) => (
                    <div key={i} onClick={() => onChange(option.value)} className={`tw-text-sm tw-transition-all tw-rounded-lg tw-font-medium tw-p-2 tw-text-gray-900 dark:tw-text-gray-300 tw-bg-gray-100 tw-border dark:tw-bg-gray-700 ${option.value === value ? "dark:tw-border-gray-300 tw-border-blue-600" : "tw-border-gray-300 dark:tw-border-none hover:tw-cursor-pointer"}`}>{option.label}</div>
                ))}
            </div>
        </label>
    )
};

// Number component
export const NumberComponent = ({ onChange, label, value }) => (
    <label className="tw-py-2 tw-block">
        <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{label}</div>
        <div className="tw-flex tw-items-center">
            {/* Minus button */}
            <div className="tw-bg-gray-100 dark:tw-bg-gray-700 dark:hover:tw-bg-gray-600 dark:tw-border-gray-600 hover:tw-bg-gray-200 tw-border tw-border-gray-300 tw-rounded-l-lg tw-p-3 tw-h-11 tw-text-gray-900 dark:tw-text-white hover:tw-cursor-pointer" onClick={() => {
                if (value - 1 >= 0) onChange(value - 1);
                else onChange(0);
            }}>
                <FaMinus size={12} />
            </div>

            {/* Number input */}
            <input type="text" className="tw-bg-gray-50 tw-outline-none focus:tw-border-[1px] tw-h-11 tw-text-center tw-text-gray-900 tw-text-sm focus:tw-border-blue-500 tw-block tw-w-full tw-py-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white" placeholder="15" value={`${value != null ? value : ""}`} onChange={(e) => {
                if (e.target.value && !isNaN(parseInt(e.target.value)) && parseInt(e.target.value) >= 0) onChange(parseInt(e.target.value));
                else onChange(0);
            }} />

            {/* Plus button */}
            <div className="tw-bg-gray-100 dark:tw-bg-gray-700 dark:hover:tw-bg-gray-600 dark:tw-border-gray-600 hover:tw-bg-gray-200 tw-border tw-border-gray-300 tw-rounded-r-lg tw-p-3 tw-h-11 tw-text-gray-900 dark:tw-text-white hover:tw-cursor-pointer" onClick={() => {
                if (value + 1 >= 0) onChange(value + 1);
                else onChange(1);
            }}>
                <FaPlus size={12} />
            </div>
        </div>
    </label>
);

export const ObjectComponent = ({ field, onChange, label, value }) => {
    const [open, setOpen] = useState(false)

    const handleToggle = useCallback(() => {
        setOpen(prevOpen => !prevOpen);
    }, []);

    const handleChange = useCallback(
        (key, newValue) => {
            onChange({ ...value, [key]: newValue });
        },
        [onChange, value]
    );

    return (
        <label className="tw-py-2 tw-block">
            <Menu as="ul" className="">
                <li className="tw-flex tw-flex-col">
                    <Menu.Button onClick={handleToggle} className="tw-w-full tw-bg-transparent tw-flex tw-justify-between tw-gap-2 tw-items-center focus:tw-outline-none tw-p-0 tw-text-left">
                        <div className="tw-flex tw-gap-3">
                            <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{label}</div>
                            <FetchWrapper field={field} Component={ComplexFetchComponent} value={value} onChange={onChange} label={label} />
                        </div>
                        <RiArrowDropDownLine className="dark:tw-text-white" size={24} />
                    </Menu.Button>
                    <Transition
                        as="div"
                        show={open}
                        enter="tw-transition tw-ease-out tw-duration-100"
                        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                        enterTo="tw-transform tw-opacity-100 tw-scale-100"
                        leave="tw-transition tw-ease-in tw-duration-75"
                        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                        leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                        className="tw-text-sm tw-overflow-hidden tw-font-medium tw-shadow-lg tw-px-2 tw-rounded tw-w-full"
                    >
                        <Menu.Items static>
                            <div className="tw-block tw-py-2">

                                {value && Object.keys(value).map((key, i) => {

                                    // Type of the field
                                    const fieldType = field.objectFields[key].type;

                                    let commonProps = {
                                        onChange: (newValue) => handleChange(key, newValue),
                                        value: value[key],
                                        label: field.objectFields[key].label
                                    }

                                    // Render the appropriate component based on the type
                                    switch (fieldType) {
                                        case 'text':
                                            return <TextComponent key={i} {...commonProps} />;
                                        case 'textarea':
                                            return <TextareaComponent key={i} {...commonProps} />;
                                        case 'number':
                                            return <NumberComponent key={i} {...commonProps} />;
                                        case 'select':
                                            const selectOptions = field.objectFields[key]?.options
                                            return <SelectComponent key={i} {...commonProps} field={{ options: selectOptions }} />;
                                        case 'radio':
                                            const radioOptions = field.objectFields[key]?.options
                                            return <RadioComponent key={i} {...commonProps} field={{ options: radioOptions }} />;
                                        case 'object':
                                            const fieldProp = field.objectFields[key]
                                            return <ObjectComponent key={i} {...commonProps} field={fieldProp} />;
                                        case 'array':
                                            const arrayProp = field.objectFields[key]
                                            return <ArrayComponent key={i} {...commonProps} field={arrayProp} />;
                                        case 'custom':
                                            const customProp = { name: key, field: field.objectFields[key] }
                                            const CustomComponent = field.objectFields[key].render
                                            return <CustomComponent key={i} {...commonProps} {...customProp} />
                                        default:
                                            return null;
                                    }
                                })}

                            </div>
                        </Menu.Items>
                    </Transition>
                </li>
            </Menu>
        </label >
    );
}

export const ArrayComponent = ({ field, onChange, label, value }) => {
    const [currentItem, setCurrentItem] = useState(null)
    const [open, setOpen] = useState(false)

    const handleToggle = useCallback(() => {
        setOpen(prevOpen => !prevOpen);
    }, []);

    const handleChange = useCallback(
        (index, key, newValue) => {
            const updatedValue = value.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        [key]: newValue
                    };
                }
                return item;
            });
            onChange(updatedValue);
        },
        [onChange, value]
    );

    const addItem = useCallback(() => onChange([...value, field.defaultItemProps]), [onChange, value]);
    const deleteItem = useCallback((index) => onChange(value.filter((_, idx) => idx !== index)), [onChange, value]);

    return (
        <label className="tw-py-2 tw-block">

            <Menu as="ul" className="">
                <li className="tw-flex tw-flex-col">
                    <Menu.Button onClick={handleToggle} className="tw-w-full tw-bg-transparent tw-flex tw-justify-between tw-gap-2 tw-items-center focus:tw-outline-none tw-p-0 tw-text-left">
                        <div className="tw-flex tw-gap-3">
                            <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{label}</div>
                            <FetchWrapper field={field} Component={ComplexFetchComponent} value={value} onChange={onChange} label={label} />
                        </div>
                        <RiArrowDropDownLine className="dark:tw-text-white" size={24} />
                    </Menu.Button>
                    <Transition
                        as="div"
                        show={open}
                        enter="tw-transition tw-ease-out tw-duration-100"
                        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                        enterTo="tw-transform tw-opacity-100 tw-scale-100"
                        leave="tw-transition tw-ease-in tw-duration-75"
                        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                        leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                        className="tw-py-1 tw-text-sm tw-overflow-hidden tw-font-medium"
                    >
                        <Menu.Items static>

                            {value?.length > 0 &&
                                <Menu as="ul" className="tw-text-sm tw-overflow-hidden tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-text-white">
                                    {value.map((item, index) => (
                                        <li key={index} className="tw-flex tw-flex-col">
                                            <Menu.Button onClick={() => {
                                                if (currentItem === index + 1) setCurrentItem(null)
                                                else setCurrentItem(index + 1)
                                            }} className="tw-w-full tw-bg-transparent tw-px-4 tw-py-3 tw-border-b tw-border-gray-300 dark:tw-border-gray-600 dark:tw-text-white focus:tw-outline-none tw-flex tw-justify-between tw-gap-2 tw-items-center tw-text-left" key={index}>
                                                <span>{field.getItemSummary(item, index)}</span>
                                                <span onClick={() => deleteItem(index)} className="hover:tw-bg-gray-100 dark:hover:tw-bg-gray-800 tw-p-1 tw-rounded">
                                                    <AiTwotoneDelete className="tw-text-secondary dark:tw-text-background tw-cursor-pointer" size={16} />
                                                </span>
                                            </Menu.Button>
                                            <Transition
                                                as="div"
                                                show={currentItem === index + 1}
                                                enter="tw-transition tw-ease-out tw-duration-100"
                                                enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                                                enterTo="tw-transform tw-opacity-100 tw-scale-100"
                                                leave="tw-transition tw-ease-in tw-duration-75"
                                                leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                                                leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                                                className="tw-py-1 tw-border-b tw-border-gray-300 dark:tw-border-gray-600"
                                            >
                                                <Menu.Items static>
                                                    <div className="tw-block tw-px-2 tw-py-2">

                                                        {Object.keys(item).map((key, i) => {
                                                            // Type of the field
                                                            const fieldType = field.arrayFields[key].type;

                                                            let commonProps = {
                                                                onChange: (newValue) => handleChange(index, key, newValue),
                                                                value: value[index][key],
                                                                label: field.arrayFields[key].label
                                                            }

                                                            // Render the appropriate component based on the type
                                                            switch (fieldType) {
                                                                case 'text':
                                                                    return <TextComponent key={`${index}${i}`} {...commonProps} />;
                                                                case 'textarea':
                                                                    return <TextareaComponent key={`${index}${i}`} {...commonProps} />;
                                                                case 'number':
                                                                    return <NumberComponent key={`${index}${i}`} {...commonProps} />;
                                                                case 'select':
                                                                    const selectOptions = field.arrayFields[key]?.options
                                                                    return <SelectComponent key={`${index}${i}`} {...commonProps} field={{ options: selectOptions }} />;
                                                                case 'radio':
                                                                    const radioOptions = field.arrayFields[key]?.options
                                                                    return <RadioComponent key={`${index}${i}`} {...commonProps} field={{ options: radioOptions }} />;
                                                                case 'object':
                                                                    const objectFieldProp = field.arrayFields[key]
                                                                    return <ObjectComponent key={`${index}${i}`} {...commonProps} field={objectFieldProp} />;
                                                                case 'array':
                                                                    const arrayFieldProp = field.arrayFields[key]
                                                                    return <ArrayComponent key={`${index}${i}`} {...commonProps} field={arrayFieldProp} />;
                                                                case 'custom':
                                                                    let customProp = { name: key, field: field.arrayFields[key] }
                                                                    const CustomComponent = field.arrayFields[key].render
                                                                    return <CustomComponent key={i} {...commonProps} {...customProp} />
                                                                default:
                                                                    return null;
                                                            }
                                                        })}

                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </li>
                                    ))}
                                </Menu>
                            }

                            <div className="tw-flex tw-justify-end tw-w-full tw-mt-1 tw-items-center">
                                <button className="tw-px-3 tw-py-1 tw-text-sm tw-w-fit tw-overflow-hidden tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-text-white hover:tw-bg-gray-100 dark:hover:tw-bg-gray-800" onClick={addItem}>Add</button>
                            </div>

                        </Menu.Items>
                    </Transition>
                </li>
            </Menu>
        </label >
    );
}


export const overrides = {
    fields: ({ children }) => <div>{children}</div>,

    fieldLabel: ({ children, label }) => (
        <label className="tw-p-2 tw-block">
            <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{label}</div>
            {children}
        </label>
    ),

    fieldTypes: {
        text: TextComponent,
        textarea: TextareaComponent,
        select: SelectComponent,
        radio: RadioComponent,
        number: NumberComponent,
        array: ArrayComponent,
        object: ObjectComponent
    },
}