import React from "react";
import { ComponentConfig, DropZone, ObjectField } from "@measured/puck";
import { Columns as ColumnsType } from 'react-feather'
import { Tooltip } from "../utils/Tooltip";
import Wrapper from "../utils/Wrapper";
import { useWebsiteContext } from "../../context/website";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../helpers'
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields, type SpacingFieldsType, spacingFields } from "../utils/CommonFields";

export type GridProps = globalCommonFieldsType & {
    distribution: "auto" | "manual";
    columns: {
        span?: number;
        spacing: SpacingFieldsType;
    }[];
};

export const Grid: ComponentConfig<GridProps> = {
    fields: {
        distribution: {
            type: "radio",
            options: [
                {
                    value: "auto",
                    label: "Auto",
                },
                {
                    value: "manual",
                    label: "Manual",
                },
            ],
            label: "Distribution",
        },
        columns: {
            type: "array",
            getItemSummary: (col, id) =>
                `Column ${id + 1}, span ${col.span ? Math.max(Math.min(col.span, 10), 1) : "auto"
                }`,
            arrayFields: {
                span: {
                    label: "Span (1-10)",
                    type: "number",
                },
                spacing: {
                    type: "object",
                    objectFields: spacingFields,
                    label: "Spacing"
                } as ObjectField<SpacingFieldsType>,
            },
            label: "Columns",
        },
        ...globalCommonFields
    },
    defaultProps: {
        distribution: "auto",
        columns: [
            {
                span: 1,
                spacing: createDefaultProps('spacing')
            },
            {
                span: 1,
                spacing: createDefaultProps('spacing')
            }
        ],
        ...createDefaultProps("globalCommon")
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const theme = websiteTheme.content;
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex;

        return (
            <Wrapper styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div
                    style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin), ...generateBorderCSS(data.border) }}>
                    <div
                        className="tw-flex tw-grid-cols-12 tw-flex-col tw-min-h-0 tw-min-w-0 md:tw-grid"
                        style={{
                            gridTemplateColumns:
                                data.distribution === "manual"
                                    ? "repeat(10, 1fr)"
                                    : `repeat(${data.columns.length}, 1fr)`,
                        }}
                    >
                        {data.columns.map(({ span, spacing }, idx) => (
                            <div
                                key={idx}
                                style={{
                                    margin: `${generateSpacingCSS(spacing.margin)}px`,
                                    padding: `${generateSpacingCSS(spacing.padding)}px`,
                                    display: "flex",
                                    flexDirection: "column",
                                    gridColumn:
                                        span && data.distribution === "manual"
                                            ? `span ${Math.max(Math.min(span, 10), 1)}`
                                            : "",
                                }}
                            >
                                <DropZone
                                    zone={`column-${idx}`}
                                    disallow={[]}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Wrapper>
        );
    },
};

export const GridWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2" : "tw-px-2 tw-py-1"} tw-border-transparent tw-rounded tw-shadow-md tw-w-fit tw-flex tw-flex-col tw-items-center tw-gap-1 tw-bg-background dark:tw-bg-backgroundDark`}>
        {showIcon &&
            (
                showText ?
                    <ColumnsType size={22} />
                    :
                    <Tooltip title="Grid">
                        <span>
                            <ColumnsType size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Grid</div>
        }
    </div>;
};