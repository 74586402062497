import ToolTip, {  TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <ToolTip placement='right' {...props} slotProps={{
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -10],
          },
        },
      ],
    },
  }} classes={{ popper: className }} />
))();