import React, { useState } from "react";
import { ComponentConfig, usePuck, ObjectField } from "@measured/puck";
import { TbRectangle } from "react-icons/tb";
import { Tooltip } from "../utils/Tooltip";
import Wrapper from "../utils/Wrapper";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../helpers'
import { useWebsiteContext } from "../../context/website";
import { Link } from 'react-router-dom'
import { getAbsolutePath } from "../../lib/utils";
import useEditingPath from "../../hooks/useEditingPath";
import BookButton from "../utils/BookButton";

export type ButtonsProps = globalCommonFieldsType & {
    buttons: (globalCommonFieldsType & {
        label: string;
        type: "custom_link" | "link_to_a_location" | "link_to_multiple_locations";
        link: string;
        typography: textFieldsType;
    })[],
    maxWidth?: number,
};

export const Buttons: ComponentConfig<ButtonsProps> = {
    fields: {
        buttons: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Button #${i + 1}`,
            arrayFields: {
                label: { type: "textarea", label: "Label" },
                type: {
                    type: "select",
                    options: [
                        { label: "Custom Link", value: "custom_link" },
                        { label: "Link to a location", value: "link_to_a_location" },
                        { label: "Link to multiple locations", value: "link_to_multiple_locations" },
                    ],
                    label: "Type",
                },
                link: { type: "text", label: "Link" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
                ...globalCommonFields
            },
            defaultItemProps: {
                label: "Default",
                type: "custom_link",
                link: "",
                typography: createDefaultProps("text"),
                ...createDefaultProps("globalCommon",
                    {
                        spacing: { padding: { left: 6, top: 4, right: 6, bottom: 4, } }
                    }),
            },
            label: "Buttons"
        },
        maxWidth: { type: "number", label: "Max Width" },
        ...globalCommonFields
    },
    defaultProps: {
        buttons: [
            {
                label: "Default",
                type: "custom_link",
                link: "",
                typography: createDefaultProps("text"),
                ...createDefaultProps("globalCommon",
                    {
                        spacing: { padding: { left: 6, top: 4, right: 6, bottom: 4, } },
                        border: { top: { style: "solid", width: 1, radius: { left: 5, right: 5 } }, right: { style: "solid", width: 1 }, bottom: { style: "solid", width: 1, radius: { left: 5, right: 5 } }, left: { style: "solid", width: 1 } }
                    }),
            },
        ],
        ...createDefaultProps("globalCommon")
    },
    render: (data) => {
        const { websiteTheme } = useWebsiteContext();
        const { isEdit } = useEditingPath();

        const handleChange = (e) => {
            const updatedData = { ...data, Buttons: e.target.textContent };

            return { updatedData: updatedData, type: "Buttons" }
        }

        const theme = websiteTheme.content
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin), ...generateBorderCSS(data.border) }} className="tw-flex tw-gap-4">
                    {
                        data.buttons.map((btn, i) =>
                            btn.type === "custom_link" ?
                                <div>
                                    <Link
                                        to={btn.link ? getAbsolutePath(btn.link, isEdit) : "#"}
                                    >
                                        <div style={{ padding: generateSpacingCSS(btn.spacing.padding), margin: generateSpacingCSS(btn.spacing.margin), ...generateBorderCSS(btn.border), backgroundColor: btn.backgroundColor.hex === "Default" ? theme.colors.secondary : btn.backgroundColor.hex }}
                                            className={`tw-text-decoration-none tw-overflow-hidden`}>
                                            {btn.label}
                                        </div>
                                    </Link>
                                </div>
                                :
                                btn.type === "link_to_a_location" ?
                                    <BookButton singleLocation label={btn.label} styles={{ padding: generateSpacingCSS(btn.spacing.padding), margin: generateSpacingCSS(btn.spacing.margin), ...generateBorderCSS(btn.border), backgroundColor: btn.backgroundColor.hex === "Default" ? theme.colors.secondary : btn.backgroundColor.hex }} />
                                    :
                                    <BookButton label={btn.label} styles={{ padding: generateSpacingCSS(btn.spacing.padding), margin: generateSpacingCSS(btn.spacing.margin), ...generateBorderCSS(btn.border), backgroundColor: btn.backgroundColor.hex === "Default" ? theme.colors.secondary : btn.backgroundColor.hex }} />

                        )
                    }
                </div>
            </Wrapper >
        );
    },
};

export const ButtonsWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return (
        <div className={`${showText ? "tw-px-5 tw-py-2" : "tw-px-2 tw-py-1"} tw-border-transparent tw-rounded tw-shadow-md tw-w-fit tw-flex tw-flex-col tw-items-center tw-gap-1 tw-bg-background dark:tw-bg-backgroundDark`}>
            {showIcon &&
                (
                    showText ?
                        <TbRectangle size={22} />
                        :
                        <Tooltip title="Buttons">
                            <span>
                                <TbRectangle size={18} />
                            </span>
                        </Tooltip>
                )
            }
            {showText &&
                <div className="Buttons-sm">Buttons</div>
            }
        </div>
    );
};