import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { AiOutlineClose } from 'react-icons/ai';
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi';

export type ToastProps = {
    align?: string;
    links: {
        label: string;
        href: string;
    }[];
    padding: {
        left: number,
        top: number,
        right: number,
        bottom: number,
    };
};

export const Toast: ComponentConfig<ToastProps> = {
    fields: {
        align: {
            type: "radio",
            options: [
                { label: "left", value: "left" },
                { label: "center", value: "center" },
            ],
            label: "Align"
        },
        padding: {
            type: "object",
            objectFields: {
                left: { type: "number" },
                top: { type: "number" },
                right: { type: "number" },
                bottom: { type: "number" },
            },
            label: "Padding"
        },
        links: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Link ${i + 1}`,
            arrayFields: {
                label: { type: "text" },
                href: { type: "text" },
            },
            defaultItemProps: {
                label: "link",
                href: "#",
            },
            label: "Link"
        },
    },
    defaultProps: {
        align: "center",
        padding: {
            left: 10,
            top: 10,
            right: 10,
            bottom: 10,
        },
        links: [],
    },

    render: (data) => {

        const handleChange = (e) => {
            const updatedData = { ...data };

            return { updatedData: updatedData, type: "Toast_theme_6_variant_1" }
        };

        const [isSuccess, setIsSuccess] = useState(true);
        const [message, setMessage] = useState("Form submitted successfully.");

        const closeToast = () => {
            setMessage(null);
        };

        return (
            <Wrapper isCenteredLayout={false} className="tw-fixed tw-bottom-6 tw-right-6 tw-z-50 tw-rounded-lg tw-overflow-hidden tw-shadow">
                <div>
                    {message &&
                        (
                            isSuccess ? (
                                <div style={{ paddingLeft: data.padding.left, paddingTop: data.padding.top, paddingRight: data.padding.right, paddingBottom: data.padding.bottom }} className="tw-flex tw-w-full tw-items-center tw-max-w-xs tw-gap-2 tw-p-4 tw-text-gray-500">
                                    <div className="tw-inline-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-8 tw-h-8 tw-text-green-500 tw-bg-green-200 tw-rounded-lg">
                                        <BiCheckCircle className="tw-w-5 tw-h-5" />
                                    </div>
                                    <div className="tw-text-sm tw-font-normal">{message}</div>
                                    <button type="button" className="tw-ms-auto -tw-mx-1.5 -tw-my-1.5 tw-text-gray-400 hover:tw-text-gray-900 tw-rounded-lg tw-p-1.5 hover:tw-bg-gray-100 focus:tw-outline-none tw-inline-flex tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-bg-transparent" onClick={closeToast} aria-label="Close">
                                        <span className="tw-sr-only">Close</span>
                                        <AiOutlineClose className="w-3 h-3" />
                                    </button>
                                </div>
                            ) : (
                                <div style={{ paddingLeft: data.padding.left, paddingTop: data.padding.top, paddingRight: data.padding.right, paddingBottom: data.padding.bottom }} className="tw-flex tw-items-center tw-w-full tw-gap-2 tw-max-w-xs tw-p-4 tw-text-gray-500">
                                    <div className="tw-inline-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-8 tw-h-8 tw-text-green-500 tw-bg-green-200 tw-rounded-lg">
                                        <BiErrorCircle className="tw-w-5 tw-h-5" />
                                    </div>
                                    <div className="tw-text-sm tw-font-normal">{message}</div>
                                    <button type="button" className="tw-ms-auto -tw-mx-1.5 -tw-my-1.5 tw-text-gray-400 hover:tw-text-gray-900 tw-rounded-lg tw-p-1.5 hover:tw-bg-gray-100 focus:tw-outline-none tw-inline-flex tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-bg-transparent" onClick={closeToast} aria-label="Close">
                                        <span className="tw-sr-only">Close</span>
                                        <AiOutlineClose className="w-3 h-3" />
                                    </button>
                                </div>
                            ))
                    }
                </div>
            </Wrapper >

        );
    },
};