import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { FaPlus, FaMinus } from "react-icons/fa";
import { EditableText } from "../../../utils/components";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";
import { useWebsiteContext } from "../../../../context/website";

export type FaqProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    items: {
        label: string,
        description: string,
    }[]
};

export const Faq: ComponentConfig<FaqProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        items: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Faq ${i + 1}`,
            arrayFields: {
                label: { type: "textarea" },
                description: { type: "textarea" },
            },
            defaultItemProps: {
                label: "",
                description: "",
            },
            label: "Questions"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "FAQ",
            typography: createDefaultProps("text", {
                fontSize: 36,
                fontWeight: 800,
                lineHeight: 1.05,
            }),
        },
        items: [
            {
                "label": "How can I book an appointment?",
                "description": "To book an appointment, you can either call our salon or spa directly, visit our website to book online."
            },
            {
                "label": "What services do you offer?",
                "description": "We offer a wide range of services including haircuts, hair coloring, manicures, pedicures, massages, facials, waxing, and more. Feel free to explore our website for a detailed list of services."
            },
            {
                "label": "Do you offer any memberships or packages?",
                "description": "Yes, we offer various memberships and packages that provide discounts on our services. You can inquire at our reception desk or check our website for more information."
            },
            {
                "label": "Do I need to bring anything for my appointment?",
                "description": "For most appointments, you don't need to bring anything special. However, if you have any specific requirements or if you're coming in for a specific service, our staff will inform you if you need to bring anything."
            },
            {
                "label": "What are your operating hours?",
                "description": "Our operating hours vary depending on the day of the week. You can find our current opening and closing hours on our website or by contacting us directly."
            },
            {
                "label": "What can I expect during my visit?",
                "description": "During your visit, you can expect a relaxing and rejuvenating experience. Our friendly staff will greet you upon arrival, discuss your needs, and guide you through your chosen services. If it's your first visit, we'll provide you with a brief orientation to ensure you're comfortable."
            }
        ],
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 25, right: 0, bottom: 25, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const [activeIndex, setActiveIndex] = useState(null);

        const toggleAnswer = (index) => {
            setActiveIndex(activeIndex === index ? null : index);
        };

        const handleChange = (e, type) => {
            e.preventDefault();
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Faq_theme_6_variant_1" }
        };

        const theme = websiteTheme.name === "theme_6" ? websiteTheme.content : themes.theme_6
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className="tw-py-8 lg:tw-py-16 tw-px-4">

                    <div className="tw-mb-6 tw-text-center">
                        <EditableText classes="" handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>
                    </div>

                    <DropZone zone="faq_theme_6_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />


                    <div className="tw-space-y-6">
                        {data.items.map((faq, i) => (
                            <div
                                key={i}
                                className="tw-border-2 tw-border-gray-200 tw-rounded-lg"
                            >
                                <button
                                    className="tw-flex tw-items-center tw-justify-between tw-w-full tw-p-6 focus:tw-outline-none"
                                    onClick={() => toggleAnswer(i)}
                                >
                                    <h1 className="tw-font-semibold tw-text-lg tw-text-gray-700">
                                        {faq.label}
                                    </h1>

                                    <span className="tw-text-white tw-bg-blue-500 tw-rounded-full tw-py-[3px] tw-px-[6px]">
                                        {activeIndex === i ? (
                                            <FaMinus size={14} />
                                        ) : (
                                            <FaPlus size={14} />
                                        )}
                                    </span>
                                </button>

                                {activeIndex === i && faq.description && (
                                    <>
                                        <hr className="tw-border-gray-200" />
                                        <p className="tw-px-6 tw-py-4 tw-text-md">
                                            {faq.description}
                                        </p>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>

                </div>
            </Wrapper>

        );
    },
};