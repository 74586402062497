import React from "react";
import "./previewloading.css";
import { FaHammer } from "react-icons/fa";

const BuilderToast = ({ category }) => {
  const categories = {
    "publishStarted": {
      loading: true,
      message: "Wait some time while we finish publishing your website."
    },
    "publishError": {
      loading: false,
      message: "Oops, couldn't publish your website."
    },
    "pageAdded": {
      loading: false,
      message: "The page has been added."
    },
    "layoutChanged": {
      loading: false,
      message: "The new layout has been applied."
    },
    "fieldChanged": {
      loading: false,
      message: "The field has been changed successfully."
    },
    "unsplashFetchError": {
      loading: false,
      message: "Oops, couldn't fetch from unsplash."
    },
    "commonError": {
      loading: false,
      message: "Oops, Something went wrong!"
    }
  }

  const currentCategory = categories[category];

  return (
    <div className="tw-h-full">
      <div className="tw-h-full tw-w-full tw-flex tw-items-center tw-gap-3">
        {currentCategory.loading ?
          <div className="builder-sk-folding-cube">
            <div className="builder-sk-cube1 builder-sk-cube" />
            <div className="builder-sk-cube2 builder-sk-cube" />
            <div className="builder-sk-cube4 builder-sk-cube" />
            <div className="builder-sk-cube3 builder-sk-cube" />
          </div>
          :
          <div className="tw-p-2 tw-rounded-full tw-bg-background dark:tw-bg-backgroundDark">
            <FaHammer size={20} />
          </div>
        }
        <div className="tw-text-center">
          {currentCategory.message}
        </div>
      </div>
    </div >
  );
};

export default BuilderToast;
