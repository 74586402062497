import { createContext, useContext } from "react";

const WebsiteContext = createContext(null);

export const useWebsiteContext = () => {
    const context = useContext(WebsiteContext);
    if (!context) {
        throw new Error('Context must be used within a provider');
    }
    return context;
};

export default WebsiteContext;