import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableButton, EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type ContactProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    description: {
        text: string;
        typography: textFieldsType
    };
    button: (globalCommonFieldsType & {
        text: string;
        typography: textFieldsType
    });
};

export const Contact: ComponentConfig<ContactProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        description: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Description"
        },
        button: {
            type: "object",
            objectFields: {
                text: { type: "text", label: "Number" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
                ...globalCommonFields
            },
            label: "Button"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Contact Us",
            typography: createDefaultProps("text", {
                fontSize: 36,
                fontWeight: 800,
                lineHeight: 1.05,
            }),
        },
        description: {
            text: "Want to know more about our services or plans? Let us know.",
            typography: createDefaultProps("text", {
                fontSize: 20,
                fontWeight: 300,
                lineHeight: 1.3,
            }),
        },
        button: {
            text: "Submit",
            typography: createDefaultProps("text"),
            ...createDefaultProps("globalCommon", {
                spacing: { padding: { left: 20, top: 12, right: 20, bottom: 12, } },
                backgroundColor: { hex: themes.theme_6.colors.secondary }
            })
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 25, right: 0, bottom: 25, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type === 'description') {
                updatedData.description.text = e.target.textContent;
            } else if (type === 'button') {
                updatedData.button.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Contact_theme_6_variant_1" }
        };

        const theme = websiteTheme.name === "theme_6" ? websiteTheme.content : themes.theme_6
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div className={`tw-mx-auto tw-max-w-screen-md`}>
                    <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className="tw-py-8 lg:tw-py-16 tw-text-center">
                        
                        <DropZone zone="contact_theme_6_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />
                        <EditableText classes="tw-mb-4" handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title}
                        </EditableText>
                        <EditableText classes="tw-mb-8 lg:tw-mb-16" handleChange={(e) => handleChange(e, "description")} data={data.description.typography}>
                            {data.description}
                        </EditableText>
                        <DropZone zone="contact_theme_6_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />

                        <form action="#" className="tw-space-y-8">
                            <div>
                                <label className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Email</label>
                                <input type="email" className="tw-shadow-sm tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-500 focus:tw-border-primary-500 tw-block tw-w-full tw-p-2.5" placeholder="name@growthzilla.com" required />
                            </div>
                            <div>
                                <label className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Subject</label>
                                <input type="text" className="tw-block tw-p-3 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg tw-border tw-border-gray-300 tw-shadow-sm focus:tw-ring-primary-500 focus:tw-border-primary-500" placeholder="How can we help you?" required />
                            </div>
                            <div>
                                <label className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Phone</label>
                                <input type="text" className="tw-block tw-p-3 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg tw-border tw-border-gray-300 tw-shadow-sm focus:tw-ring-primary-500 focus:tw-border-primary-500" placeholder="(123) 456-7890" required />
                            </div>
                            <div className="tw-sm:tw-col-span-2">
                                <label className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Message</label>
                                <textarea rows={5} className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg tw-shadow-sm tw-border tw-border-gray-300 tw-focus:tw-ring-primary-500 focus:tw-border-primary-500" placeholder="Leave a message..."></textarea>
                            </div>
                            <div className="tw-flex tw-justify-center md:tw-justify-end">
                                <EditableButton handleChange={(e) => handleChange(e, "button")} styles={{ padding: generateSpacingCSS(data.button.spacing.padding), margin: generateSpacingCSS(data.button.spacing.margin), backgroundColor: data.button.backgroundColor.hex, ...generateBorderCSS(data.button.border) }} data={{ typography: data.button.typography }} isSubmit>
                                    {data.button.text}
                                </EditableButton>
                            </div>
                        </form>
                    </div>
                </div>
            </Wrapper >
        );
    },
};