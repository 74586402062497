import { ReactNode } from "react";
import { Link } from 'react-router-dom'
import { getAbsolutePath } from "../../../lib/utils";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import BookButton from "../../utils/BookButton";
import Wrapper from "../../utils/Wrapper";
import { useWebsiteContext } from "../../../context/website";
import { ObjectComponent } from "../../config/overrides";
import { ImageField } from "../../puck_fields";

const FooterLink = ({ children, href, classes }: { children: ReactNode; href: string; classes?: string }) => {
  return (
    <li style={{ fontFamily: "Montserrat" }} className={`tw-pb-2 ${classes}`}>
      <Link
        to={href}
        className="tw-no-underline"
      >
        {children}
      </Link>
    </li>
  );
};

const FooterList = ({
  children,
  title,
  classes,
  theme
}: {
  children: ReactNode;
  title: string;
  classes?: string;
  theme: any;
}) => {

  return (
    <div className={`${classes} tw-leading-5`}>
      <h3 className="tw-mb-4 tw-text-2xl tw-font-semibold tw-uppercase">
        {title}
      </h3>

      <div style={{ backgroundColor: theme.colors.secondary }} className="tw-mb-4 tw-h-[2px] tw-w-[50px] tw-rounded"></div>

      <ul className="tw-list-none tw-font-medium tw-flex tw-flex-col tw-gap-1 tw-text-xl">
        {children}
      </ul>
    </div>
  );
};

const Footer = ({ children, editMode, theme, pages, assets, data }: { children: ReactNode, editMode: boolean, theme: any, pages: any, assets: any, data: any }) => {
  const { websiteData } = useWebsiteContext();
  const footerStyle: React.CSSProperties = {
    backgroundColor: theme.colors.primary,
    color: theme.colors.text,
    fontFamily: `"${theme.fonts[0]}", serif`
  };

  return (
    <footer style={footerStyle} className="tw-relative tw-overflow-hidden">
      <Wrapper noStyles className="tw-py-6 lg:tw-py-8 tw-relative tw-z-10">
        <div className="tw-grid tw-gap-8 md:tw-gap-16 tw-grid-cols-2 lg:tw-grid-cols-3 tw-mx-auto">
          <FooterList theme={theme} title="About Us">
            {data?.description &&
              <div style={{ color: theme.colors.neutral }} className="tw-font-['Roboto'] tw-font-normal">
                {data.description}
              </div>
            }
            <div className={`tw-inline-flex tw-mt-5 tw-w-full`}>
              <BookButton label="MAKE APPOINTMENT" styles={{ backgroundColor: theme.colors.secondary }} classes="tw-py-3 tw-px-4 tw-font-semibold tw-text-lg" />
            </div>
          </FooterList>
          <FooterList theme={theme} title="Quick Links">
            {
              pages.map((page, i) =>
                <FooterLink key={i} classes="tw-font-normal" href={getAbsolutePath(page.link, editMode)}>{page.name}</FooterLink>
              )
            }
          </FooterList>

          <div className="tw-flex tw-flex-col tw-gap-8">
            <FooterList theme={theme} title="Subscription">
              <div className={`tw-flex`}>
                <label htmlFor="email-address" className="tw-sr-only">
                  Email address
                </label>
                <input
                  style={{ backgroundColor: theme.colors.primary, fontFamily: "Montserrat", color: theme.colors.text }}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="tw-min-w-0 tw-flex-auto tw-border-0 tw-p-3.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-white/10 focus:tw-outline-none"
                  placeholder="Enter your email"
                />
                <button
                  style={{ backgroundColor: theme.colors.secondary }}
                  type="submit"
                  className="tw-flex-none tw-py-3.5 tw-px-4 tw-font-semibold tw-shadow-sm tw-text-white"
                >
                  Sign up
                </button>
              </div>
            </FooterList>

            <FooterList theme={theme} title="Follow us">
              <div className="tw-flex tw-items-center tw-gap-2">
                <a href={data?.links?.facebook || "#"} target="_blank" className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center" style={{ color: theme.colors.secondary }}>
                  <FaFacebookF />
                </a>
                <a href={data?.links?.twitter || "#"} target="_blank" className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center" style={{ color: theme.colors.secondary }}>
                  <FaTwitter />
                </a>
                <a href={data?.links?.linkedin || "#"} target="_blank" className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center" style={{ color: theme.colors.secondary }}>
                  <FaLinkedinIn />
                </a>
                <a href={data?.links?.instagram || "#"} target="_blank" className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center" style={{ color: theme.colors.secondary }}>
                  <FaInstagram />
                </a>
              </div>
            </FooterList>
          </div>
        </div>

        <div style={{ fontFamily: "Montserrat" }} className="tw-text-base tw-text-center tw-mt-[100px] tw-relative">© Copyright 2023 - <Link to={getAbsolutePath("home", editMode)} className="hover:tw-underline" style={{ color: theme.colors.secondary }}>{websiteData.name}</Link>. All Rights Reserved.
        </div>
      </Wrapper >

      {
        data?.backgroundImages?.url_1 &&
        <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-bottom-0 tw-left-0 tw-hidden md:tw-block">
          <img
            src={data.backgroundImages.url_1}
            alt=""
            className="tw-w-full tw-h-full tw-object-contain"
          />
        </div>
      }

      {
        data?.backgroundImages?.url_2 &&
        <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-bottom-0 tw-right-0">
          <img
            src={data.backgroundImages.url_2}
            alt=""
            className="tw-w-full tw-h-full tw-object-contain"
          />
        </div>
      }

    </footer >
  );
};

const FooterEditor = ({ data, setData }) => {
  const config = {
    label: "Footer",
    onChange: (val) => setData(val),
    value: data,
    field: {
      type: "object",
      objectFields: {
        backgroundImages: {
          type: "object",
          objectFields: {
            url_1: { label: "Url 1", ...ImageField },
            url_2: { label: "Url 2", ...ImageField }
          },
          label: "Images"
        },
        links: {
          type: "object",
          objectFields: {
            facebook: { label: "Facebook", type: "textarea" },
            twitter: { label: "Twitter", type: "textarea" },
            linkedin: { label: "Linked in", type: "textarea" },
            instagram: { label: "Instagram", type: "textarea" },
          },
          label: "Links"
        },
        description: { type: "textarea", label: "Description" },
      }
    }
  }

  return <ObjectComponent {...config} />
}

export { Footer, FooterEditor };