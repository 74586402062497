import { useWebsiteContext } from "../../context/website";

// Helper function to generate CSS padding or margin string
export const generateSpacingCSS = (spacing: { left: number; top: number; right: number; bottom: number }): string => {
    return `${spacing.top}px ${spacing.right}px ${spacing.bottom}px ${spacing.left}px`;
}

export const generateBorderCSS = (border: {
    top: {
        style: string;
        width: number;
        color: any;
        radius: { left: number, right: number };
    };
    right: {
        style: string;
        width: number;
        color: any;
    };
    bottom: {
        style: string;
        width: number;
        color: any;
        radius: { left: number, right: number };
    };
    left: {
        style: string;
        width: number;
        color: any;
    };
}): object => {
    const styles = { borderTop: `${border.top.width}px ${border.top.style} ${border.top.color.hex}`, borderRight: `${border.right.width}px ${border.right.style} ${border.right.color.hex}`, borderBottom: `${border.bottom.width}px ${border.bottom.style} ${border.bottom.color.hex}`, borderLeft: `${border.left.width}px ${border.left.style} ${border.left.color.hex}`, borderRadius: `${border.top.radius.left}px ${border.top.radius.right}px ${border.bottom.radius.right}px ${border.bottom.radius.left}px` }
    return styles;
}


// look at websiteTheme and get the current font if the font is Default
export const GetCurrentFont = (font) => {
    const { websiteTheme } = useWebsiteContext();

    const currentFont = font === "Default" ? `"${websiteTheme.content.fonts[0]}", serif` : font;
    return currentFont
}