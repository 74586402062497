import { ReactNode } from "react";
import { useWebsiteContext } from "../../context/website";

export type WrapperProps = {
    className?: string;
    children: ReactNode;
    styles?: React.CSSProperties;
    isCenteredLayout?: Boolean;
    noStyles?: Boolean;
};


const Wrapper = ({
    children,
    className,
    isCenteredLayout = true,
    styles = {},
    noStyles = false
}: WrapperProps) => {

    const { websiteTheme } = useWebsiteContext();

    const defaultStyles: React.CSSProperties = {
        backgroundColor: websiteTheme.content.colors.background,
        color: websiteTheme.content.colors.text,
        fontFamily: `"${websiteTheme.content.fonts[0]}", serif`
    };

    // Merge default styles with provided styles, giving precedence to provided styles
    const mergedStyles: React.CSSProperties = {
        ...defaultStyles,
        ...styles
    };

    return (
        <div style={noStyles ? null : mergedStyles}
            className={className}
        >
            <div className={isCenteredLayout ? "tw-mx-auto tw-max-w-screen-xl tw-px-4 sm:tw-px-6 lg:tw-px-8" : ""}>
                {children}
            </div>
        </div>
    );
};

export default Wrapper