import { CustomField } from "@measured/puck";
import { ColorPicker } from 'primereact/colorpicker';

export const ColorField: CustomField<string> = {
    type: "custom",
    render: ({ onChange, value, field }: {
        onChange: (value: any) => void;
        value: string;
        field: any;
    }) => {
        return (
            <div className="tw-relative tw-py-2">
                <label className="tw-block">
                    <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">{field.label}</div>

                    {/* color is stored with # but ColorPicker takes hex without # */}
                    <ColorPicker format="hex" value={value.slice(1)} onChange={(e) => onChange(`#${e.value}`)} />
                </label>
            </div>
        )
    },
}