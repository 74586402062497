import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { DefaultRootProps } from "@measured/puck";
import { useWebsiteContext } from "../../context/website";
import BookModal from "../utils/BookModal";
import { Helmet } from 'react-helmet';
import { themeData } from "../../theme";

const importHeader = (theme) => {
  const module = require("../page_sections/Header");
  return module["Header_" + theme]
}

const importFooter = (theme) => {
  const module = require("../page_sections/Footer");
  return module["Footer_" + theme]
}

export type RootProps = {
  children: ReactNode;
} & DefaultRootProps;

function Root({ children, editMode }: RootProps) {
  // theme and book modal
  const { websiteTheme, websitePages, websiteFonts, showBookModal, setShowBookModal } = useWebsiteContext();

  const Header = importHeader(websiteTheme.header.name);
  const Footer = importFooter(websiteTheme.footer.name);

  const bookModalSetter = useCallback((show) => {
    setShowBookModal({ show: show })
  }, [])

  const MemoizedBookModal = useMemo(() => showBookModal?.show ? <BookModal setShowBookModal={bookModalSetter} /> : null, [showBookModal])

  const data = { header: { ...themeData[websiteTheme.header.name].header, ...(websiteTheme?.data?.header ? websiteTheme.data.header : {}) }, footer: { ...themeData[websiteTheme.footer.name].footer, ...(websiteTheme?.data?.footer ? websiteTheme.data.footer : {}) } }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"></link>
        {websiteFonts.map((font) => {
          const fontUrl = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(font)}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap`;
          return <link key={font} id={font} href={fontUrl} rel="stylesheet" crossOrigin="anonymous" />;
        })}
      </Helmet>

      <div dir="ltr" style={{ fontFamily: `"${websiteTheme.global.fonts[0]}", serif`, backgroundColor: websiteTheme.global.colors.background, color: websiteTheme.global.colors.text }}>
        <Header editMode={editMode} pages={websitePages} theme={websiteTheme.header} assets={websiteTheme?.assets} data={data.header} />
        <main style={{ fontFamily: `"${websiteTheme.content.fonts[0]}", serif`, backgroundColor: websiteTheme.content.colors.background, color: websiteTheme.content.colors.text }}>
          {children}
          {MemoizedBookModal}
        </main>
        <Footer editMode={editMode} pages={websitePages} theme={websiteTheme.footer} assets={websiteTheme?.assets} data={data.footer} />
      </div>
    </>
  );
}

export default Root;