import { ReactNode } from "react";
import { Link } from 'react-router-dom'
import { getAbsolutePath } from "../../../lib/utils";
import Wrapper from "../../utils/Wrapper";
import { useWebsiteContext } from "../../../context/website";
import { ObjectComponent } from "../../config/overrides";
import { ImageField } from "../../puck_fields";

const FooterLink = ({ children, href }: { children: ReactNode; href: string }) => {
  return (
    <li className="tw-pb-2">
      <Link
        href={href}
        className="tw-no-underline tw-text-sm"
      >
        {children}
      </Link>
    </li>
  );
};

const FooterList = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => {
  return (
    <div>
      <h3 className="tw-mb-6 tw-text-base tw-font-semibold tw-uppercase">
        {title}
      </h3>
      <ul className="tw-list-none tw-font-medium tw-flex tw-flex-col tw-gap-1">
        {children}
      </ul>
    </div>
  );
};

const Footer = ({ children, editMode, theme, pages, assets, data }: { children: ReactNode, editMode: boolean, theme: any, pages: any, assets: any, data: any }) => {
  const { websiteData } = useWebsiteContext();

  const footerStyle: React.CSSProperties = {
    backgroundColor: theme.colors.primary,
    color: theme.colors.text,
    fontFamily: `"${theme.fonts[0]}", serif`
  };

  return (
    <footer style={footerStyle}>
      <Wrapper noStyles className="tw-py-6 lg:tw-py-8">
        <div className="md:tw-flex md:tw-justify-between">
          <div className="tw-mb-8 md:tw-mb-0">
            <Link to={getAbsolutePath("home", editMode)} className="tw-flex tw-items-center">
              <img src={assets?.logo} className="tw-h-8 tw-me-3" alt="Logo" />
              <span className="tw-self-center tw-text-2xl tw-font-semibold tw-whitespace-nowrap">{websiteData.name}</span>
            </Link>
          </div>
          <div className="tw-grid tw-gap-8 md:tw-gap-16 tw-grid-cols-2 lg:tw-grid-cols-3 tw-mx-auto">
            <FooterList title="Section A">
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
            </FooterList>
            <FooterList title="Section B">
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
            </FooterList>
            <FooterList title="Section C">
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
            </FooterList>
          </div>
        </div>
        <hr style={{ borderColor: theme.colors.secondary }} className="tw-my-6 tw-border-gray-200 sm:tw-mx-auto lg:tw-my-8" />
        <div className="tw-text-sm tw-text-center">© 2023 <Link to={getAbsolutePath("home", editMode)} className="hover:tw-underline">{websiteData.name}</Link>. All Rights Reserved.
        </div>
      </Wrapper>
    </footer>
  );
};

const FooterEditor = ({ data, setData }) => {
  const config = {
    label: "Footer",
    onChange: (val) => setData(val),
    value: data,
    field: {
      type: "object",
      objectFields: {
        backgroundImages: {
          type: "object",
          objectFields: {
            url_1: { label: "Url 1", ...ImageField },
            url_2: { label: "Url 2", ...ImageField }
          },
          label: "Images"
        }
      }
    }
  }

  return <ObjectComponent {...config} />
}

export { Footer, FooterEditor };