/* eslint-disable radix */
/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetDefaultWebsiteValues } from "../theme"
import { getPages } from "../api";

// Importing pages.json only if in production mode
const initialWebsitePages = [];
const initialWebsiteData = {
    name: ""
};

const { websiteTheme: initialWebsiteTheme, websiteFonts: initialWebsiteFonts } = GetDefaultWebsiteValues("theme_2");

const useWebsiteData = () => {
    const [websiteDataLoading, setWebsiteDataLoading] = useState(true);

    // for image upload portal
    const [uploadImageModal, setUploadImageModal] = useState(null);

    // for book modal
    const [showBookModal, setShowBookModal] = useState(null);

    // current website data
    const [websiteData, setWebsiteData] = useState(initialWebsiteData); // read only

    // current website theme
    const [websiteTheme, setWebsiteTheme] = useState(initialWebsiteTheme);

    // pages in website
    const [websitePages, setWebsitePages] = useState(initialWebsitePages);

    // current website fonts
    const [websiteFonts, setWebsiteFonts] = useState(initialWebsiteFonts);

    const [cfSettings, setCfSettings] = useState(null);

    const { projectId, business } = useParams();

    useEffect(() => {
        const fetchPages = async () => {
            const pagesResponse = await getPages(business, projectId);
            if (pagesResponse?.success) {
                setWebsitePages(pagesResponse.data.pages);

                // website data i.e. project data
                setWebsiteData({ ...websiteData, name: pagesResponse?.data.builder_data.name })
                const builderData = JSON.parse(pagesResponse?.data.builder_data.data);
                setWebsiteTheme(builderData.websiteTheme);
                setWebsiteFonts(builderData.websiteFonts);
                const settings = pagesResponse.data?.builder_data?.cfsettings ? JSON.parse(pagesResponse.data.builder_data.cfsettings) : null;
                setCfSettings(settings)
            } else {
                toast?.error("Problem fetching pages.");
            }
            setWebsiteDataLoading(false);
        };

        fetchPages();
    }, [projectId]);

    return {
        websiteDataLoading,
        setWebsiteDataLoading,
        websiteData,
        setWebsiteData,
        websiteTheme,
        setWebsiteTheme,
        websitePages,
        setWebsitePages,
        websiteFonts,
        setWebsiteFonts,
        cfSettings,
        setCfSettings,
        uploadImageModal,
        setUploadImageModal,
        showBookModal,
        setShowBookModal
    };
};

export default useWebsiteData;
