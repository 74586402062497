import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableLink } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type BreadcrumbProps = globalCommonFieldsType & {
    links: {
        label: string;
        href: string;
        typography: textFieldsType;
    }[];
    backgroundImage: string;
};

export const Breadcrumb: ComponentConfig<BreadcrumbProps> = {
    fields: {
        links: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Link ${i + 1}`,
            arrayFields: {
                label: { type: "text", label: "Label" },
                href: { type: "text", label: "Href" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            defaultItemProps: {
                label: "link",
                href: "#",
                typography: createDefaultProps("text", {
                    fontSize: 15,
                    fontWeight: 500,
                    lineHeight: 1.2,
                }),
            },
            label: "Link"
        },
        backgroundImage: { ...ImageField, label: "Background Image" },
        ...globalCommonFields
    },
    defaultProps: {
        links: [],
        backgroundImage: "https://images.fresha.com/locations/location-profile-images/946641/1208694/15afd873-4648-4026-80cb-8aaf7d3e5d96.jpg?class=venue-gallery-large",
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 75, right: 0, bottom: 75, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type == "linkLabel") {
                updatedData.links[identifier].label = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Breadcrumb_theme_2_variant_1" }
        };

        const theme = websiteTheme.name === "theme_2" ? websiteTheme.content : themes.theme_2
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper isCenteredLayout={false} className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div className={`tw-mx-auto tw-max-w-screen-md`}>
                    <nav style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className={`tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-w-full tw-text-center tw-relative tw-z-10`} aria-label="Breadcrumb">
                        <h1>{data.links.length > 0 ? data.links[data.links.length - 1].label : "Home"}</h1>
                        <ol className={`tw-flex tw-items-center tw-space-x-1 tw-mx-auto tw-font-['Roboto']`}>
                            <li>
                                <div className="tw-flex">
                                    <a href="#" className="tw-ml-2 hover:tw-text-secondary">Home</a>
                                </div>
                            </li>
                            {data.links.length > 0 && data.links.map((link, i) => (
                                <li key={i}>
                                    <div className="tw-flex tw-items-center">
                                        <span>/</span>
                                        <EditableLink classes="tw-ml-1" href={link.href} handleChange={(e) => handleChange(e, "linkLabel", i)} data={{ typography: link.typography }}>
                                            {link.label}
                                        </EditableLink>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </nav>

                    <div
                        style={{
                            backgroundImage: `url("${data.backgroundImage}")`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            bottom: 0,
                            left: 0,
                            opacity: 0.15,
                            zIndex: 5
                        }}
                    ></div>

                </div>
            </Wrapper>

        );
    },
};