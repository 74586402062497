export const themes = {
    "theme_1": {
        name: "theme_1",
        layout: {
        },
        colors: {
            primary: "#EFFBFB",
            secondary: "#218380",
            success: "#32CD32",
            warning: "#FFD700",
            background: "#FFFFFF",
            outline: "#000000",
            text: "#000000",
            card: "#FFFFFF",
            neutral: "#868E96"
        },
        fonts: ["Playfair Display", "Roboto"],
    },
    "theme_2": {
        name: "theme_2",
        layout: {
        },
        colors: {
            primary: "#000000",
            secondary: "#F38625",
            success: "#32CD32",
            warning: "#FFD700",
            background: "#121212",
            outline: "#000000",
            text: "#FFFFFF",
            card: "#FFFFFF",
            neutral: "#FFFFFF"
        },
        fonts: ["Marcellus", "Montserrat"],
    },
    "theme_6": {
        name: "theme_6",
        layout: {
        },
        colors: {
            primary: "#FFA07A",
            secondary: "#6A5ACD",
            success: "#00FF7F",
            warning: "#FFD700",
            background: "#F8F8FF",
            outline: "#000000",
            text: "#000000",
            card: "#FFFFFF",
            neutral: "#868E96"
        },
        fonts: ["Georgia", "Palatino", "serif"],
    },
}

export const colorPalette = {
    primary: [
        "#515000",
        "#A2A001",
        "#CBC801",
        "#F4F001",
        "#FEFB35",
        "#FEFB48",
        "#FEFB5D",
        "#FEFC85",
        "#FFFDAE",
        "#FFFED6",
        "#EFFBFB",
        "#FFA07A",
        "#000000",
    ],

    secondary: [
        "#00477A",
        "#005FA3",
        "#0077CC",
        "#0083E0",
        "#0092FA",
        "#33AAFF",
        "#5CBBFF",
        "#99D5FF",
        "#C2E5FF",
        "#2B8A3E",
        "#2F9E44",
        "#37B24D",
        "#40C057",
        "#51CF66",
        "#69DB7C",
        "#8CE99A",
        "#B2F2BB",
        "#D3F9D8",
        "#218380",
        "#EBFBEE",
        "#C92A2A",
        "#E03131",
        "#F03E3E",
        "#FA5252",
        "#FF6B6B",
        "#F38625",
        "#FF8787",
        "#FFA8A8",
        "#FFC9C9",
        "#FFE3E3",
        "#FFF5F5",
        "#593C21",
        "#77502C",
        "#8B5D33",
        "#A46E3D",
        "#B37942",
        "#C28B5B",
        "#CDA279",
        "#D8B797",
        "#6A5ACD",
        "#E9D6C3",
        "#F9F5F0"
    ],

    neutral: [
        "#212529",
        "#343A40",
        "#495057",
        "#868E96",
        "#ADB5BD",
        "#CED4DA",
        "#DEE2E6",
        "#E9ECEF",
        "#F1F3F5",
        "#F8F9FA",
        "#FFFFFF",
    ],

    background: [
        "#121212",
        "#FFFFFF",
    ],
}

export const fonts = [
    "Default",
    "Arial",
    "Arial Black",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
    "Open Sans",
    "Poppins",
    "Lato",
    "Marcellus",
    "Caramel",
    "Lobster",
    "Pacifico",
    "Roboto Mono",
    "Courgette",
    "Alfa Slab One",
    "Bebas Neue",
    "Amatic SC",
    "Great Vibes",
    "Kaushan Script",
    "Yeseva One",
    "Sacramento",
    "Allura",
    "Parisienne",
    "Unbounded",
    "Audiowide",
    "Playball",
    "Monoton",
    "Cinzel Decorative",
    "Italianno",
    "Playfair Display",
    "Yatra One",
    "Fredericka the Great",
    "Graduate",
    "Mrs Saint Delafield",
    "Arizonia",
    "Bungee Inline",
    "Petit Formal Script",
    "Tilt Prism",
    "Dela Gothic One",
    "Roboto",
    "Merienda",
    "Bungee Shade",
    "Aboreto",
    "Anton",
    "Space Grotesk",
    "Raleway",
    "Lily Script One"
];

export const componentImages = {
    "HeroSection_theme_1_variant_1": "https://via.placeholder.com/150?text=HeroSection_theme_1_variant_1",
    "HeroSection_theme_2_variant_1": "https://via.placeholder.com/150?text=HeroSection_theme_2_variant_1",
    "HeroSection_theme_6_variant_1": "https://via.placeholder.com/150?text=HeroSection_theme_6_variant_1",
    "NewsLetter_theme_1_variant_1": "https://via.placeholder.com/150?text=NewsLetter_theme_1_variant_1",
    "NewsLetter_theme_6_variant_1": "https://via.placeholder.com/150?text=NewsLetter_theme_6_variant_1",
    "Contact_theme_1_variant_1": "https://via.placeholder.com/150?text=Contact_theme_1_variant_1",
    "Contact_theme_2_variant_1": "https://via.placeholder.com/150?text=Contact_theme_2_variant_1",
    "Contact_theme_6_variant_1": "https://via.placeholder.com/150?text=Contact_theme_6_variant_1",
    "Services_theme_1_variant_1": "https://via.placeholder.com/150?text=Services_theme_1_variant_1",
    "Services_theme_2_variant_1": "https://via.placeholder.com/150?text=Services_theme_2_variant_1",
    "Breadcrumb_theme_1_variant_1": "https://via.placeholder.com/150?text=Breadcrumb_theme_1_variant_1",
    "Breadcrumb_theme_2_variant_1": "https://via.placeholder.com/150?text=Breadcrumb_theme_2_variant_1",
    "Breadcrumb_theme_6_variant_1": "https://via.placeholder.com/150?text=Breadcrumb_theme_6_variant_1",
    "Faq_theme_6_variant_1": "https://via.placeholder.com/150?text=Faq_theme_6_variant_1",
    "About_theme_1_variant_1": "https://via.placeholder.com/150?text=About_theme_1_variant_1",
    "Gallery_theme_1_variant_1": "https://via.placeholder.com/150?text=Gallery_theme_1_variant_1",
    "Gallery_theme_2_variant_1": "https://via.placeholder.com/150?text=Gallery_theme_2_variant_1",
    "Gallery_theme_6_variant_1": "https://via.placeholder.com/150?text=Gallery_theme_6_variant_1",
    "Team_theme_1_variant_1": "https://via.placeholder.com/150?text=Team_theme_1_variant_1",
    "Team_theme_2_variant_1": "https://via.placeholder.com/150?text=Team_theme_2_variant_1",
    "Pricing_theme_1_variant_1": "https://via.placeholder.com/150?text=Pricing_theme_1_variant_1",
    "Pricing_theme_2_variant_1": "https://via.placeholder.com/150?text=Pricing_theme_2_variant_1",
    "Overview_theme_1_variant_1": "https://via.placeholder.com/150?text=Overview_theme_1_variant_1",
    "Stats_theme_1_variant_1": "https://via.placeholder.com/150?text=Stats_theme_1_variant_1",
    "Stats_theme_2_variant_1": "https://via.placeholder.com/150?text=Stats_theme_2_variant_1",
    "Location_theme_1_variant_1": "https://via.placeholder.com/150?text=Location_theme_1_variant_1",
    "Location_theme_2_variant_1": "https://via.placeholder.com/150?text=Location_theme_2_variant_1",
    "Testimonial_theme_1_variant_1": "https://via.placeholder.com/150?text=Testimonial_theme_1_variant_1",
    "Testimonial_theme_2_variant_1": "https://via.placeholder.com/150?text=Testimonial_theme_2_variant_1",
    "Testimonial_theme_6_variant_1": "https://via.placeholder.com/150?text=Testimonial_theme_6_variant_1",
    "Toast_theme_6_variant_1": "https://via.placeholder.com/150?text=Toast_theme_6_variant_1"
};

export const themeImages = {
    "theme_1": "https://via.placeholder.com/150?text=theme_1",
    "theme_2": "https://via.placeholder.com/150?text=theme_2",
    "theme_6": "https://via.placeholder.com/150?text=theme_6",
}

export const themeData = {
    "theme_1": {
        header: {
            logoType: "both"
        },
        footer: {
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam magna urna, laoreet sit amet urna sed, pharetra condimentum ex. Vestibulum nisi quam, luctus sed massa eleifend, dapibus pellentesque velit. Proin gravida dolor non quam vestibulum, in sodales erat aliquam.",
            backgroundImages: {
                url_1: "https://cdn.pixabay.com/photo/2017/11/03/11/33/rose-2914093_1280.png",
                url_2: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png"
            },
            phone: "(910) 491-1926",
            email: "info@salon.com",
            location: "Fayetteville NC 28311"
        }
    },
    "theme_2": {
        header: {
            logoType: "both"
        },
        footer: {
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam magna urna, laoreet sit amet urna sed, pharetra condimentum ex. Vestibulum nisi quam, luctus sed massa eleifend, dapibus pellentesque velit. Proin gravida dolor non quam vestibulum, in sodales erat aliquam.",
            backgroundImages: {
                url_1: "https://cdn.pixabay.com/photo/2017/11/03/11/33/rose-2914093_1280.png",
                url_2: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png"
            },
            links: {
                facebook: "",
                twitter: "",
                linkedin: "",
                instagram: "",
            }
        }
    },
    "theme_6": {
        header: {
            logoType: "both"
        },
        footer: {
        }
    },
}

export const GetDefaultWebsiteValues = (themeId) => ({
    websiteTheme: {
        global: themes[themeId],
        header: themes[themeId],
        content: themes[themeId],
        footer: themes[themeId],
        data: themeData[themeId]
    },
    websiteFonts: Object.values(themes).reduce((acc, theme) => acc.concat(theme.fonts), [])
})