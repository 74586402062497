import React, { useState } from "react";
import { FaLink } from 'react-icons/fa'
import { ComponentConfig, usePuck, ObjectField } from "@measured/puck";
import { Tooltip } from "../utils/Tooltip";
import Wrapper from "../utils/Wrapper";
import { useWebsiteContext } from "../../context/website";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../helpers'
import { EditableLink } from "../utils/components";

export type LinkProps = globalCommonFieldsType & {
    text?: string;
    href?: string;
    maxWidth?: number;
    typography: textFieldsType;
};

export const Link: ComponentConfig<LinkProps> = {
    fields: {
        text: { type: "text", label: "Text" },
        href: { type: "text", label: "Url" },
        maxWidth: { type: "number", label: "Max Width" },
        typography: {
            type: "object",
            objectFields: textFields,
            label: "Typography"
        },
        ...globalCommonFields
    },
    defaultProps: {
        href: "#",
        text: "Link",
        typography: createDefaultProps("text"),
        ...createDefaultProps("globalCommon")
    },
    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e) => {
            const updatedData = { ...data, text: e.target.textContent };

            return { updatedData: updatedData, type: "Link" }
        }

        const theme = websiteTheme.content;
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex;

        return (
            <Wrapper styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div
                    style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin), ...generateBorderCSS(data.border) }}>
                    <EditableLink href={data.href} classes="" handleChange={handleChange} data={{ typography: data.typography }}>
                        {data.text}
                    </EditableLink>
                </div>
            </Wrapper>
        );
    },
};

export const LinkWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2" : "tw-px-2 tw-py-1"} tw-border-transparent tw-rounded tw-shadow-md tw-w-fit tw-flex tw-flex-col tw-items-center tw-gap-1 tw-bg-background dark:tw-bg-backgroundDark`}>
        {showIcon &&
            (
                showText ?
                    <FaLink size={22} />
                    :
                    <Tooltip title="Link">
                        <span>
                            <FaLink size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Link</div>
        }
    </div>;
};