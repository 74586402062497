import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { BsTrophyFill } from "react-icons/bs";
import { Tooltip } from "../../../utils/Tooltip";
import { EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { MdLocationOn } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { CgPhone } from "react-icons/cg";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type LocationProps = globalCommonFieldsType & {
    locations: {
        label: {
            text: string;
            typography: textFieldsType
        };
        address: {
            text: string;
            typography: textFieldsType
        };
        mail: {
            text: string;
            typography: textFieldsType
        };
        phone: {
            text: string;
            typography: textFieldsType
        };
    }[];
};

export const Location: ComponentConfig<LocationProps> = {
    fields: {
        locations: {
            type: "array",
            getItemSummary: (item, i) => item.label.text || `Location #${i + 1}`,
            arrayFields: {
                label: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Title"
                },
                address: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Address"
                },
                mail: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Mail"
                },
                phone: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Phone"
                },
            },
            label: "Locations"
        },
        ...globalCommonFields
    },
    defaultProps: {
        locations: [
            {
                label: {
                    text: "Arizona",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 500,
                        lineHeight: 1.2,
                    }),
                },
                address: {
                    text: "Cleansing and Massaging",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
                mail: {
                    text: "info@riyasjustthreading.com",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
                phone: {
                    text: "(910) 491-1926",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
            },
            {
                label: {
                    text: "California",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.2,
                    }),
                },
                address: {
                    text: "Threading",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
                mail: {
                    text: "info@justthreading.com",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
                phone: {
                    text: "(910) 491-1956",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
            },
            {
                label: {
                    text: "Virginia",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 5500,
                        lineHeight: 1.2,
                    }),
                },
                address: {
                    text: "Hairdressing",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
                mail: {
                    text: "info@justthreading.com",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
                phone: {
                    text: "(910) 491-2026",
                    typography: createDefaultProps("text", {
                        fontSize: 18,
                        fontWeight: 5500,
                        lineHeight: 1.3,
                    }),
                },
            },
        ],
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 100, right: 0, bottom: 100, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type == "locationTitle") {
                updatedData.locations[identifier].label.text = e.target.textContent;
            } else if (type == "locationAddress") {
                updatedData.locations[identifier].address.text = e.target.textContent;
            } else if (type == "locationMail") {
                updatedData.locations[identifier].mail.text = e.target.textContent;
            } else if (type == "locationPhone") {
                updatedData.locations[identifier].phone.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Location_theme_1_variant_1" }
        };

        const theme = websiteTheme.name === "theme_1" ? websiteTheme.content : themes.theme_1

        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }}>
                    <div className="tw-grid lg:tw-grid-cols-2 tw-gap-16 md:tw-w-fit tw-mt-16 tw-mx-auto">
                        {data.locations.map((location, i) =>
                            <div key={i} className="tw-flex tw-flex-col tw-gap-5">
                                <EditableText handleChange={(e) => handleChange(e, "locationTitle")} data={location.label.typography}>
                                    {location.label.text}
                                </EditableText>
                                <div className="tw-flex tw-flex-col">
                                    <div className="tw-flex tw-flex-col tw-gap-5 tw-py-6 md:tw-py-10 tw-px-4 md:tw-px-12 lg:tw-px-16 tw-shadow-md">
                                        <div className="tw-flex tw-gap-4">
                                            <span><MdLocationOn size={22} /></span>
                                            <EditableText handleChange={(e) => handleChange(e, "locationAddress", i)} data={location.address.typography}>
                                                {`${location.address.text}`}
                                            </EditableText>
                                        </div>
                                        <div className="tw-flex tw-gap-4">
                                            <span><IoMdMail size={20} /></span>
                                            <EditableText handleChange={(e) => handleChange(e, "locationMail", i)} data={location.mail.typography}>
                                                {location.mail.text}
                                            </EditableText>
                                        </div>
                                        <div className="tw-flex tw-gap-4">
                                            <span><CgPhone size={20} /></span>
                                            <EditableText handleChange={(e) => handleChange(e, "locationPhone", i)} data={location.phone.typography}>
                                                {location.phone.text}
                                            </EditableText>
                                        </div>
                                    </div>
                                    <div className="tw-h-[250px] md:tw-h-[300px] tw-w-full tw-relative">
                                        {/* Embedding Google Map using iframe */}
                                        <iframe
                                            title="Google Map"
                                            id="map"
                                            src={`https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=${encodeURIComponent(location.label.text)}+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`}
                                            width="100%"
                                            height="100%"
                                            allowFullScreen={true}
                                            loading="lazy"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </Wrapper >
        );
    },
};

export const LocationWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2 tw-w-full" : "tw-px-2 tw-py-1 tw-w-fit"} tw-border-transparent tw-rounded tw-shadow-md tw-flex tw-items-center tw-gap-4 tw-bg-background dark:tw-bg-backgroundDark tw-transition-all tw-duration-300 tw-ease-in-out`}>
        {showIcon &&
            (
                showText ?
                    <span>
                        <BsTrophyFill size={22} />
                    </span>
                    :
                    <Tooltip title="Location">
                        <span>
                            <BsTrophyFill size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Location</div>
        }
    </div>;
};