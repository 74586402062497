/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as AppRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";  
import Renderer from "./Renderer"
import "./tailwind.output.css";

ReactDOM.render(
  <AppRouter>
    <Switch>

      <Route exact
        path="/"
      >
        <Redirect to="/home" />
      </Route>

      <Route exact
        path="/:page"
        render={() =>
          <Renderer />
        }
      />

    </Switch>
  </AppRouter>,
  document.getElementById("root")
);
