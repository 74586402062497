
import { useState } from "react"
import { BsCardImage } from "react-icons/bs";
import { useWebsiteContext } from "../../context/website";
import { usePuck } from "@measured/puck";
import useEditingPath from "../../hooks/useEditingPath";

interface ImageFrameProps {
    imageUrl: any;
    setImageUrl: any;
    style?: React.CSSProperties;
    className?: string;
    alt?: string;
    fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}

const ImageFrame: React.FC<ImageFrameProps> = ({ imageUrl, setImageUrl, style = {}, className = "", alt = "", fit = "cover" }) => {
    const { setUploadImageModal } = useWebsiteContext();
    const { appState, dispatch } = usePuck();
    const { isEdit } = useEditingPath();

    const makeChanges = (data) => {
        const { updatedData, type } = setImageUrl(data);
        dispatch({
            type: "replace",
            destinationIndex: appState.ui.itemSelector.index,
            destinationZone: appState.ui.itemSelector.zone,
            data: { props: { ...updatedData }, type: type },
        });
    }

    const openImageModal = () => {
        setUploadImageModal({
            show: true,
            url: imageUrl,
            setUrl: makeChanges
        });
    }

    return (
        <div style={style} onDoubleClick={isEdit && openImageModal} className={className}>
            {imageUrl ? (
                <img style={{ objectFit: fit }} className="tw-w-full tw-h-full tw-transition-all tw-object-top"
                    src={imageUrl}
                    alt={alt}
                />
            ) : (
                <div className="tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full">
                    <BsCardImage size={80} />
                </div>
            )}
            hey
        </div>
    )
}

export default ImageFrame