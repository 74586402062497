/* eslint-disable react/button-has-type */
import React, { useMemo } from 'react';
import useWebsiteData from "../common/hooks/useWebsiteData";
import WebsiteContext from "../common/context/website"
import "./index.css"
import Renderer from "./renderer"

export default function Index() {
    const {
        websiteTheme,
        websitePages,
        websiteFonts,
        websiteData,
        websiteDataLoading,
        showBookModal,
        setShowBookModal
    } = useWebsiteData();

    const websiteContextValue = useMemo(() => ({
        websiteTheme,
        websitePages,
        websiteFonts,
        websiteDataLoading,
        websiteData,
        showBookModal,
        setShowBookModal
    }), [websiteTheme, websitePages, websiteFonts, websiteDataLoading, websiteData, showBookModal]);

    return (
        <WebsiteContext.Provider value={websiteContextValue}>
            <Renderer />
        </WebsiteContext.Provider >
    )
}