import React, { useState } from "react";
import { ComponentConfig, DropZone, ObjectField, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { BsTrophyFill } from "react-icons/bs";
import { Tooltip } from "../../../utils/Tooltip";
import { EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type TeamProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    description: {
        text: string;
        typography: textFieldsType
    };
    teammates: {
        title: {
            text: string;
            typography: textFieldsType
        };
        description: {
            text: string;
            typography: textFieldsType
        };
        url: string;
    }[];
    backgroundImages: {
        url_1: string,
        url_2: string,
    }
};

export const Team: ComponentConfig<TeamProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        description: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Description"
        },
        teammates: {
            type: "array",
            getItemSummary: (item, i) => item.title.text || `Teammate #${i + 1}`,
            arrayFields: {
                title: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Title"
                },
                description: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Description"
                },
                url: { ...ImageField, label: "Url" },
            },
            defaultItemProps: {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                title: {
                    text: "Maria Williams",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Hair Stylist",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            label: "Teammates"
        },
        backgroundImages: {
            type: "object",
            objectFields: {
                url_1: { ...ImageField, label: "Url 1" },
                url_2: { ...ImageField, label: "Url 2" },
            },
            label: "Images"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "It'S NOT YOU, IT'S YOUR EYEBROWS",
            typography: createDefaultProps("text", {
                font: { name: "Roboto" },
                fontSize: 25,
                fontWeight: 600,
                lineHeight: 1.1,
            }),
        },
        description: {
            text: "The salon that leaves a smile on your face forever.",
            typography: createDefaultProps("text", {
                fontSize: 15,
                fontWeight: 500,
                lineHeight: 1.2,
                color: { hex: themes.theme_1.colors.neutral }
            }),
        },
        teammates: [
            {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                title: {
                    text: "Maria Williams",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Hair Stylist",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            {
                url: "https://static.vecteezy.com/system/resources/previews/004/675/721/non_2x/middle-aged-woman-having-a-head-massage-in-a-beauty-salon-photo.jpg",
                title: {
                    text: "Blair Bliss",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Massage Specialist",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            {
                url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaNOqVWA3K-nJXKi9XfncBfxI-WIk7HwsaIZutHHAn9Q&s",
                title: {
                    text: "Tini Tiny",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Threading Specialist",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
        ],
        backgroundImages: {
            url_1: "https://cdn.pixabay.com/photo/2017/11/03/11/33/rose-2914093_1280.png",
            url_2: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png"
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 100, right: 0, bottom: 100, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type === 'description') {
                updatedData.description.text = e.target.textContent;
            } else if (type == "teammateTitle") {
                updatedData.teammates[identifier].title.text = e.target.textContent;
            } else if (type == "teammateDescription") {
                updatedData.teammates[identifier].description.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Team_theme_1_variant_1" }
        };

        const theme = websiteTheme.name === "theme_1" ? websiteTheme.content : themes.theme_1
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div className="tw-relative tw-z-10" style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }}>
                    <div className={`tw-flex tw-flex-col tw-z-10 tw-gap-4 tw-text-center`}>
                        <DropZone zone="team_theme_1_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />

                        <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>
                        <EditableText handleChange={(e) => handleChange(e, "description")} data={data.description.typography}>
                            {data.description.text}
                        </EditableText>


                        <DropZone zone="team_theme_1_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />
                    </div>

                    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-5 tw-w-fit tw-mt-10 tw-mx-auto">
                        {data.teammates.map((teammate, i) =>
                            <div key={i} style={{ backgroundColor: theme.colors.background }} className="tw-w-[310px] tw-h-[465px] tw-relative tw-flex tw-justify-center tw-items-center">
                                <div style={{ backgroundColor: theme.colors.secondary }} className="tw-w-[310px] tw-h-[80px] tw-absolute tw-top-0">
                                </div>

                                <div className="tw-w-[235px] tw-h-[395px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4">
                                    <div className="tw-w-[220px] tw-h-[295px] tw-relative">
                                        <img
                                            src={teammate.url}
                                            alt={teammate.title.text}
                                            className="tw-w-full tw-h-full tw-object-cover"
                                        />
                                    </div>

                                    <div className="tw-text-center tw-flex tw-flex-col tw-gap-1">
                                        <EditableText handleChange={(e) => handleChange(e, "teammateTitle", i)} data={teammate.title.typography}>
                                            {teammate.title.text}
                                        </EditableText>
                                        <EditableText handleChange={(e) => handleChange(e, "teammateDescription", i)} data={teammate.description.typography}>
                                            {teammate.description.text}
                                        </EditableText>
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>

                </div>

                <div className="tw-w-[180px] tw-h-[160px] tw-absolute tw-bottom-0 tw-left-0 tw-hidden md:tw-block tw-z-[5]">
                    <img
                        src={data.backgroundImages.url_1}
                        alt=""
                        className="tw-w-full tw-h-full tw-object-contain"
                    />
                </div>

                <div className="tw-w-[180px] tw-h-[160px] tw-absolute tw-top-0 tw-right-0 tw-z-[5]">
                    <img
                        src={data.backgroundImages.url_2}
                        alt=""
                        className="tw-w-full tw-h-full tw-object-contain"
                    />
                </div>
            </Wrapper >
        );
    },
};

export const TeamWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2 tw-w-full" : "tw-px-2 tw-py-1 tw-w-fit"} tw-border-transparent tw-rounded tw-shadow-md tw-flex tw-items-center tw-gap-4 tw-bg-background dark:tw-bg-backgroundDark tw-transition-all tw-duration-300 tw-ease-in-out`}>
        {showIcon &&
            (
                showText ?
                    <span>
                        <BsTrophyFill size={22} />
                    </span>
                    :
                    <Tooltip title="Team">
                        <span>
                            <BsTrophyFill size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Team</div>
        }
    </div>;
};