import React, { useState } from "react";
import { ComponentConfig, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableButton, EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type NewsLetterProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    align?: "left" | "center";
    textAlign?: "left" | "center";
    email: {
        text: string;
        typography: textFieldsType
    };
    button: (globalCommonFieldsType & {
        text: string;
        typography: textFieldsType
    });
};

export const NewsLetter: ComponentConfig<NewsLetterProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        align: {
            type: "radio",
            options: [
                { label: "left", value: "left" },
                { label: "center", value: "center" },
            ],
            label: "Letter Align"
        },
        textAlign: {
            type: "radio",
            options: [
                { label: "left", value: "left" },
                { label: "center", value: "center" },
            ],
            label: "Text Align"
        },
        email: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Email"
        },
        button: {
            type: "object",
            objectFields: {
                text: { type: "text", label: "Number" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
                ...globalCommonFields
            },
            label: "Button"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Join Our Newsletter",
            typography: createDefaultProps("text", {
                fontSize: 36,
                fontWeight: 500,
                lineHeight: 1.05,
            }),
        },
        align: "center",
        textAlign: "center",
        email: {
            text: "example@gmail.com",
            typography: createDefaultProps("text", {
                fontSize: 35,
                fontWeight: 600,
                lineHeight: 1.3,
            }),
        },
        button: {
            text: "Subscribe",
            typography: createDefaultProps("text"),
            ...createDefaultProps("globalCommon", {
                spacing: { padding: { left: 14, top: 14, right: 14, bottom: 14, } },
                backgroundColor: { hex: themes.theme_1.colors.secondary }
            })
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 64, right: 0, bottom: 64, } } })
    },
    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type === 'email') {
                updatedData.email.text = e.target.textContent;
            } else if (type === 'button') {
                updatedData.button.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "NewsLetter_theme_1_variant_1" }
        };

        const theme = websiteTheme.name === "theme_1" ? websiteTheme.content : themes.theme_1
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className={`${data.textAlign === "left" ? "tw-text-left" : "tw-text-center"} tw-relative tw-isolate tw-overflow-hidden tw-py-16 sm:py-24 lg:py-32`}>
                    <div className={`${data.align === "left" ? "" : "tw-mx-auto"} tw-max-w-3xl lg:tw-max-w-4xl`}>

                        <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>

                        <div className={`${data.align === "left" ? "" : "tw-mx-auto"} tw-mt-8 tw-flex tw-max-w-md tw-gap-x-4`}>
                            <label htmlFor="email-address" className="tw-sr-only">
                                Email address
                            </label>
                            <input
                                style={{ backgroundColor: theme.colors.background }}
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="tw-min-w-0 tw-flex-auto tw-border-0 tw-p-3.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-black/10 tw-font-['Roboto']"
                                placeholder="Enter your email"
                            />
                            <EditableButton handleChange={(e) => handleChange(e, "button")} styles={{ padding: generateSpacingCSS(data.button.spacing.padding), margin: generateSpacingCSS(data.button.spacing.margin), backgroundColor: data.button.backgroundColor.hex, ...generateBorderCSS(data.button.border) }} data={{ typography: data.button.typography }} isSubmit>
                                {data.button.text}
                            </EditableButton>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    },
};