/* eslint-disable react/button-has-type */
import { memo, useMemo, useEffect } from "react";
import { Render } from "@measured/puck";
import "@measured/puck/dist/index.css";
import { config } from "../common/puck/config"
import { useData } from "../common/hooks/useData";
import useEditingPath from "../common/hooks/useEditingPath";
import { useWebsiteContext } from "../common/context/website";
import { ThreeDotsWave } from '../common/lib/Loading';

const Renderer = memo(() => {
    const { path } = useEditingPath();;
    const { websiteTheme } = useWebsiteContext();

    const { resolvedData, loading } = useData({
        path,
    });

    const puckData = useMemo(() => ({
        resolvedData, loading, path
    }), [resolvedData, loading, path]);

    useEffect(() => {
        document.body.style.backgroundColor = websiteTheme.global.colors.background;
        document.body.style.color = websiteTheme.global.colors.text;

        return () => {
            document.body.style.backgroundColor = '';
            document.body.style.color = '';
        };
    }, [websiteTheme.global.colors]);

    if (puckData.loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundColor: "white" }}>
                <ThreeDotsWave />
            </div>
        );
    }

    else if (puckData.resolvedData) {
        return <MemoizedPuckRenderer puckData={puckData} />
    }

    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div>
                <h1>404</h1>
                <p>Page does not exist.</p>
            </div>
        </div>
    );
})

const MemoizedPuckRenderer = memo(({ puckData }) => {
    return <Render config={config} data={puckData.resolvedData} />
})

export default Renderer