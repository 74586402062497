import React, { useState } from "react";
import { ComponentConfig, usePuck, ObjectField } from "@measured/puck";
import { Type } from 'react-feather'
import { Tooltip } from "../utils/Tooltip";
import { EditableText } from "../utils/components";
import Wrapper from "../utils/Wrapper";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../helpers'
import { useWebsiteContext } from "../../context/website";

export type TextProps = globalCommonFieldsType & {
    text?: string,
    maxWidth?: number,
    typography: textFieldsType;
};

export const Text: ComponentConfig<TextProps> = {
    fields: {
        text: { type: "textarea", label: "Text" },
        maxWidth: { type: "number", label: "Max Width" },
        typography: {
            type: "object",
            objectFields: textFields,
            label: "Typography"
        },
        ...globalCommonFields
    },
    defaultProps: {
        text: "Text",
        typography: createDefaultProps("text"),
        ...createDefaultProps("globalCommon")
    },
    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e) => {
            const updatedData = { ...data, text: e.target.textContent };

            return { updatedData: updatedData, type: "Text" }
        }

        const theme = websiteTheme.content
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex
        const currentFont = GetCurrentFont(data.typography.font.name)

        return (
            <Wrapper styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin), ...generateBorderCSS(data.border) }}>
                    <EditableText classes="" handleChange={handleChange} data={{ ...data.typography, font: { name: currentFont } }}>
                        {data.text}
                    </EditableText>
                </div>
            </Wrapper >
        );
    },
};

export const TextWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return (
        <div className={`${showText ? "tw-px-5 tw-py-2" : "tw-px-2 tw-py-1"} tw-border-transparent tw-rounded tw-shadow-md tw-w-fit tw-flex tw-flex-col tw-items-center tw-gap-1 tw-bg-background dark:tw-bg-backgroundDark`}>
            {showIcon &&
                (
                    showText ?
                        <Type size={22} />
                        :
                        <Tooltip title="Text">
                            <span>
                                <Type size={18} />
                            </span>
                        </Tooltip>
                )
            }
            {showText &&
                <div className="text-sm">Text</div>
            }
        </div>
    );
};