import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableButton, EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type ContactProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    button: (globalCommonFieldsType & {
        text: string;
        typography: textFieldsType
    });
};

export const Contact: ComponentConfig<ContactProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        button: {
            type: "object",
            objectFields: {
                text: { type: "text", label: "Number" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
                ...globalCommonFields
            },
            label: "Button"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Please fill up the form below and we will get in touch with you",
            typography: createDefaultProps("text", {
                font: { name: "Roboto" },
                fontSize: 30,
                fontWeight: 500,
                lineHeight: 1.3,
            }),
        },
        button: {
            text: "Submit",
            typography: createDefaultProps("text"),
            ...createDefaultProps("globalCommon", {
                spacing: { padding: { left: 12, top: 12, right: 12, bottom: 12, } },
                backgroundColor: { hex: themes.theme_2.colors.secondary }
            })
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 25, right: 0, bottom: 25, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type === 'button') {
                updatedData.button.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Contact_theme_2_variant_1" }
        };

        const theme = websiteTheme.name === "theme_2" ? websiteTheme.content : themes.theme_2
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div className={`tw-mx-auto tw-max-w-screen-md`}>
                    <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className="tw-py-8 lg:tw-py-16 tw-px-4">
                        <div className={`tw-flex tw-flex-col tw-z-10 tw-gap-4 tw-text-center`}>
                            <DropZone zone="contact_theme_2_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />

                            <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                                {data.title.text}
                            </EditableText>

                            <div style={{ backgroundColor: theme.colors.secondary }} className={`tw-h-[4px] tw-w-[60px] tw-rounded tw-mx-auto`}></div>

                            <DropZone zone="contact_theme_2_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />
                        </div>
                        <form action="#" className="tw-space-y-8 tw-mt-10" style={{ fontFamily: "Montserrat" }}>
                            <div>
                                <input style={{ borderColor: theme.colors.neutral, color: theme.colors.neutral, backgroundColor: backgroundColor }} type="email" className="tw-shadow-sm tw-border tw-block tw-w-full tw-p-4" placeholder="Your Name" required />
                            </div>
                            <div>
                                <input style={{ borderColor: theme.colors.neutral, color: theme.colors.neutral, backgroundColor: backgroundColor }} type="email" className="tw-shadow-sm tw-border tw-block tw-w-full tw-p-4" placeholder="Your Email" required />
                            </div>
                            <div>
                                <input style={{ borderColor: theme.colors.neutral, color: theme.colors.neutral, backgroundColor: backgroundColor }} type="text" className="tw-block tw-p-4 tw-w-full tw-shadow-sm tw-border" placeholder="Your Phone" required />
                            </div>
                            <div>
                                <input style={{ borderColor: theme.colors.neutral, color: theme.colors.neutral, backgroundColor: backgroundColor }} type="text" className="tw-block tw-p-4 tw-w-full tw-border tw-shadow-sm" placeholder="Subject" required />
                            </div>
                            <div className="tw-sm:tw-col-span-2">
                                <textarea style={{ borderColor: theme.colors.neutral, color: theme.colors.neutral, backgroundColor: backgroundColor }} rows={5} className="tw-block tw-p-4 tw-w-full tw-shadow-sm tw-border" placeholder="Message"></textarea>
                            </div>
                            <div className="tw-flex tw-justify-center">
                                <EditableButton handleChange={(e) => handleChange(e, "button")} styles={{ padding: generateSpacingCSS(data.button.spacing.padding), margin: generateSpacingCSS(data.button.spacing.margin), backgroundColor: data.button.backgroundColor.hex, ...generateBorderCSS(data.button.border) }} data={{ typography: data.button.typography }} isSubmit>
                                    {data.button.text}
                                </EditableButton>
                            </div>
                        </form>
                    </div>
                </div>
            </Wrapper>

        );
    },
};